<template>
  <div class="relative mt-2 lg:grid lg:grid-cols-2 lg:gap-8 mt-12 lg:items-center">
    <div class="relative">
      <div class="flex lg:space-x-4 flex-col-reverse lg:flex-row items-center">
        <div>
          <h4 class="text-2xl leading-8 font-extrabold text-cool-gray-700 tracking-tight sm:text-3xl sm:leading-9">
            Cotiza tu seguro fácil y rápido
          </h4>
        </div>
      </div>

      <p class="mt-4 max-w-3xl mx-auto text-xl leading-7 text-gray-500">
        No importa donde estés, cotizar un seguro con Cabsa es muy fácil, puedes
        realizar el proceso desde cualquier dispositivo, solo necesitas una
        conexión a Internet, tu computadora, Smartphone o Tablet.
      </p>
    </div>
    <!-- <img class="relative mx-auto lg:w-2/3 max-w-xl" src="@/assets/images/ilustraciones/ilustraciones_cotiza.svg"/> -->
  </div>
</template>
