<template>
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="mx-auto max-w-7xl w-full text-center lg:text-left">
      <div class="px-4 sm:px-8">
        <p class="text-3xl tracking-tight leading-7 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-5xl lg:text-4xl xl:text-4xl">
          Seguro de flotillas:<br />
          <span class="text-3xl tracking-tight leading-7 font-extrabold sm:text-4xl sm:leading-none md:text-5xl lg:text-4xl xl:text-4xl text-orange-500">
            Somos tu aliado que garantiza  
          </span>
          <br />la protección de tus unidades 
        </p>
        <br />
        <b><span class="uppercase text-2xl tracking-tight leading-7 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-2xl lg:text-2xl xl:text-2xl">Flotillas</span></b>
        <p class="mt-3 mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 xl:pr-64">
          En Cabsa sabemos lo especial y único que es tu negocio, por ello podemos ayudarte a crear 
          un plan a tu medida que cubra todas tus necesidades de seguro de transporte, buscando
          siempre las mejores condiciones y el mejor precio. 
        </p>
      </div>
    </div>
  </div>
</template>
 