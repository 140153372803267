<template>
<!-- Step 5 help -->
<div v-show="help"   @keyup.esc="help = false" class="fixed inset-0 overflow-hidden">
  <div class="absolute inset-0 overflow-hidden">
    <div v-show="help" class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <!-- <section @click.away="help = false;" class="absolute inset-y-0 right-0 pl-10 max-w-full flex"> -->
    <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
      <div class="w-screen max-w-md" x-description="Slide-over panel, show/hide based on slide-over state." v-show="help" x-transition:enter="transform transition ease-in-out duration-500 sm:duration-700" x-transition:enter-start="translate-x-full" x-transition:enter-end="translate-x-0" x-transition:leave="transform transition ease-in-out duration-500 sm:duration-700" x-transition:leave-start="translate-x-0" x-transition:leave-end="translate-x-full">
        <div class="h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll">
          <header class="px-4 sm:px-6">
            <div class="flex items-start justify-between space-x-3">
              <p class="leading-6 text-orange-500 font-semibold tracking-wide uppercase">Ayuda</p>
              <div class="h-7 flex items-center">
                <button @click="help = false" aria-label="Close panel" class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150">
                  <svg class="h-6 w-6" x-description="Heroicon name: x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            </div>
          </header>
          <div class="relative flex-1 px-4 sm:px-6">
            <div class="border-b pb-4">
              <div class="prose">
                <h2 class="leading-7 font-medium text-gray-900">
                  Sobre nuestros productos
                </h2>
                <p>Cabsa inició con el objetivo de facilitar el proceso de compra de un seguro de autos, hoy en día nos hemos desarrolado para ofrecer protección para todos los aspectos de tu vida.</p>
                <p>Para tu <b>Seguro de Auto</b> confiamos en 7 empresas que te brindarán la protección necesaria para transitar de forma tranquila por las carreteras y puentes federales.</p>
                <p>Para aquellos imprevistos te ofrecemos un <b>Seguro de Gastos Médicos Mayores</b> , pensando en que solo deberás preocuparte por tu pronta recuperación y no por los gastos hospitalarios que una enfermedad o accidente grave podrían causar.</p>
                <p>Para los soñadores, también tenemos un <b>Seguro de Ahorro</b> que le pondrá número y fecha a tus objetivos y proyectos de vida.</p>
              </div>
              <div class="mt-3">
                <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-orange-700 bg-orange-100 hover:text-orange-500 hover:bg-orange-50 focus:outline-none focus:shadow-outline-orange focus:border-orange-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Automóviles
                </a>
              </div>
              <div class="mt-3">
                <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-orange-700 bg-orange-100 hover:text-orange-500 hover:bg-orange-50 focus:outline-none focus:shadow-outline-orange focus:border-orange-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Gastos médicos
                </a>
              </div>
              <div class="mt-3">
                <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-orange-700 bg-orange-100 hover:text-orange-500 hover:bg-orange-50 focus:outline-none focus:shadow-outline-orange focus:border-orange-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Ahorro
                </a>
              </div>
            </div>
            <div class="flex justify-between items-center mt-4">
              <p class="font-medium text-sm">No estas seguro de esto?</p>
              <div class="text-xs text-blue-400 text-center flex items-center hover:underline cursor-pointer">
                <svg class="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
                <p>Chatea con nosotros</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
</template>
<script>
import { inject } from "vue";
export default {
  name:"Help",

  setup() {
    const help = inject('help');

    
    return {
      help,
    }
  }
}
</script>
