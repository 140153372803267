<style scoped>
.dataTables_wrapper {
  overflow-x: auto !important;
}
</style>

<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="false"
      :color="'#bfd243'"
      :height="100"
      :width="350"
      :is-full-page="true"
      :loader="loader"
    >
    </loading>

    <!-- Filters -->
    <div class="block sm:flex">
      <div class="block w-full md:flex md:space-x-6 space-y-3 sm:space-y-0">
        <div class="px-3 w-full">
          <label
            class="block text-sm font-medium leading-5 text-gray-700"
            >Folio</label
          >
          <input
            v-model="policyForm.folio"
            type="text"
            class="
              form-input
              py-3
              px-4
              block
              w-full
              transition
              ease-in-out
              duration-150
            "
            :class="{ 'bg-red-100 border-red-400': v$.folio.$error }"
            :state="v$.folio.$dirty ? !v$.folio.$error : null"
            id="input_id"
          />
          <div v-if="v$.folio.$error" class="text-red-600">
            <span>
              {{ v$.folio.$errors[0].$message }}
            </span>
          </div>
        </div>
        <div class="px-3 w-full mt-5">
          <label
            class="block text-sm font-medium leading-5 text-gray-700"
            >No. Póliza</label
          >
          <input
            v-model="policyForm.numero_poliza"
            type="text"
            class="
              form-input
              py-3
              px-4
              block
              w-full
              transition
              ease-in-out
              duration-150
            "
            id="input_no_poliza"
          />
         
        </div>
      </div>
    </div>

    <div class="w-full my-4">
      <div class="md:float-right pb-4">
        <button 
          class=" w-full md:w-auto lg:w-2/2 xl:w-2/2 sm:mx-auto md:mx-auto lg:mx-2 xl:mx-2 my-1 shadow bg-blue-500 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded inline-flex items-center" 
          type="button" 
          v-on:click="getPolicyPdf"
        >
          Buscar
        </button>
      </div>
      <div class="md:float-right pb-4">
        
        <button 
          id="downloadPdf"
          class=" w-full md:w-auto lg:w-2/2 xl:w-2/2 sm:mx-auto md:mx-auto lg:mx-2 xl:mx-2 my-1 shadow bg-green-500 hover:bg-green-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded inline-flex items-center" 
          type="button" 
          v-on:click="downloadPolicyPdf"
          v-show="showDownloadPdf"
        >
          Descargar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
import { computed, onMounted, reactive, ref } from "vue-demi";
import {
  helpers,
  minLength,
  required
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import "@/assets/css/custom.css";
// import Swal from 'sweetalert2';
import {
  hasLetters,
} from "@/helpers/text.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Policy from "@/classes/policy.js";
export default {
  components: {
    Loading
  },
  setup() {
    const isLoading = ref(false);
    const loader = ref("spinner");
    const urlPolicy = ref("");
    const showDownloadPdf = ref(false)
    let policyForm = reactive({
      folio: "",
      numero_poliza: ""
    });
    const rulesPolicyForm = computed(() => {
      return {
        folio: {
          required: helpers.withMessage(
            "El campo FOLIO es requerido",
            required
          ),
          hasLetters: helpers.withMessage(
            "Este campo no permite ningun tipo de letra",
            hasLetters
          ),
          minLength: helpers.withMessage(
            "El campo FOLIO tiene que tener como minimo 1 caracter númerico",
            minLength(1)
          ),
        },
        policyNumber: {
          required: helpers.withMessage(
            "El campo NO. POLIZA es requerido",
            required
          ),
          hasLetters: helpers.withMessage(
            "Este campo no permite ningun tipo de letra",
            hasLetters
          ),
          minLength: helpers.withMessage(
            "El campo NO. POLIZA tiene que tener como minimo 1 caracter númerico",
            minLength(1)
          ),
        }
      };
    });

    onMounted(async () => {
      isLoading.value = true;  
      isLoading.value = false;
    })
    
    const getPolicyPdf = async () => {
      try {
        showDownloadPdf.value = false
        isLoading.value = true;
        const policy = new Policy(policyForm.folio, policyForm.numero_poliza);
        let res = await policy.get().then(res => res).catch(error => {console.log(error)});
        console.log(res)
        if (res.status == 200) {
          showDownloadPdf.value = true
          urlPolicy.value = res.data.respuesta.url_poliza_pdf;
          isLoading.value = false;
        }else{
          isLoading.value = false;
          console.log(res.data.respuesta.message);
        }
      } catch (error) {
        console.log("ERROR => ", error)
        isLoading.value = false;
      }
      
    };

    function downloadPolicyPdf(){
      window.open(urlPolicy.value, '_blank');
    }

    let v$ = useVuelidate(rulesPolicyForm, policyForm);
    return {
      isLoading,
      loader,
      urlPolicy,
      getPolicyPdf,
      downloadPolicyPdf,
      policyForm,
      v$,
      showDownloadPdf,
    };
  },
};
</script>