<template>
  <div class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
    <main class="lg:relative">
      <div x-data="{ openPanel=1 }">
        <div class="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8" >
          <div class="max-w-3xl mx-auto">
            <h2 class="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10" >
              Preguntas frecuentes
            </h2>
            <div class="mt-6 border-t-2 border-gray-200 pt-6">
              <dl>
                <div>
                  <dt class="text-lg leading-7">
                    <button v-on:click="changeOpenPanel(1)" class="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                      <span class="font-medium text-gray-900">
                        ¿Qué es un seguro?
                      </span>
                      <span class="ml-6 h-7 flex items-center">
                        <svg class="rotate-0 h-6 w-6 transform" v-bind:class="{ '-rotate-180': openPanel === 1, 'rotate-0': !(openPanel === 1) }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                        </svg>
                      </span>
                    </button>
                  </dt>
                  <transition class="mt-2 pr-12" 
                    enter-class="duration-150 ease-out" 
                    enter-active-class="opacity-0 scale-95" 
                    enter-to-class="opacity-100 scale-100" 
                    leave-class="duration-100 ease-in"
                    leave-active-class="opacity-100 scale-100" 
                    leave-to-class="opacity-0 scale-95" 
                  v-show="openPanel === 1">
                    <p class="text-base leading-6 text-gray-500">
                      Es un contrato entre la compañía de seguros y el
                      “asegurado”, donde se establecen el cobro y la cobertura
                      que tendrán ambas partes, así como sus derechos y
                      obligaciones.
                    </p>
                  </transition>
                </div>
                <div class="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt class="text-lg leading-7">
                      <button v-on:click="changeOpenPanel(2)" class="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                        <span class="font-medium text-gray-900">
                          ¿Qué es el seguro de Cobertura Limitada?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <svg class="rotate-0 h-6 w-6 transform" v-bind:class="{ '-rotate-180': openPanel === 2, 'rotate-0': !(openPanel === 2) }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <transition class="mt-2 pr-12" 
                    enter-class="duration-150 ease-out" 
                    enter-active-class="opacity-0 scale-95" 
                    enter-to-class="opacity-100 scale-100" 
                    leave-class="duration-100 ease-in"
                    leave-active-class="opacity-100 scale-100" 
                    leave-to-class="opacity-0 scale-95" 
                    v-show="openPanel === 2">
                      <p class="text-base leading-6 text-gray-500">
                        Este tipo de seguro cubre el robo total del vehículo
                        asegurado, así como los daños ocasionados a terceros al
                        igual que el de Responsabilidad civil.
                      </p>
                    </transition>
                  </div>
                </div>
                <div class="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt class="text-lg leading-7">
                      <button v-on:click="changeOpenPanel(3)" class="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                        <span class="font-medium text-gray-900">
                          ¿Cuál es el seguro de Cobertura Amplia?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <svg class="rotate-0 h-6 w-6 transform" v-bind:class="{ '-rotate-180': openPanel === 3, 'rotate-0': !(openPanel === 3) }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <transition class="mt-2 pr-12" 
                    enter-class="duration-150 ease-out" 
                    enter-active-class="opacity-0 scale-95" 
                    enter-to-class="opacity-100 scale-100" 
                    leave-class="duration-100 ease-in"
                    leave-active-class="opacity-100 scale-100" 
                    leave-to-class="opacity-0 scale-95" 
                    v-show="openPanel === 3" >
                      <p class="text-base leading-6 text-gray-500">
                        Es el seguro que cubre la mayoría de las necesidades de
                        los automovilistas porque cubre daños a terceros, robo
                        parcial o total y los daños del vehículo asegurado. Para
                        cubrir el daño propio se debe pagar un deducible.
                      </p>
                    </transition>
                  </div>
                </div>
                <div class="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt class="text-lg leading-7">
                      <button v-on:click="changeOpenPanel(4)" class="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                        <span class="font-medium text-gray-900">
                          ¿Qué es un deducible?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <svg class="rotate-0 h-6 w-6 transform" v-bind:class="{ '-rotate-180': openPanel === 4, 'rotate-0': !(openPanel === 4) }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <transition class="mt-2 pr-12" 
                    enter-class="duration-150 ease-out" 
                    enter-active-class="opacity-0 scale-95" 
                    enter-to-class="opacity-100 scale-100" 
                    leave-class="duration-100 ease-in"
                    leave-active-class="opacity-100 scale-100" 
                    leave-to-class="opacity-0 scale-95" 
                    v-show="openPanel === 4">
                      <p class="text-base leading-6 text-gray-500">
                        Es un monto que se debe pagar a la aseguradora en caso
                        de tener un accidente, estos los absorbe el contratante
                        del seguro.
                      </p>
                    </transition>
                  </div>
                </div>
                <div class="mt-6 border-t border-gray-200 pt-6">
                  <div>
                    <dt class="text-lg leading-7">
                      <button v-on:click="changeOpenPanel(5)" class="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                        <span class="font-medium text-gray-900">
                          ¿Cuáles son los métodos de pago de Cabsa?
                        </span>
                        <span class="ml-6 h-7 flex items-center">
                          <svg class="rotate-0 h-6 w-6 transform" v-bind:class="{ '-rotate-180': openPanel === 5, 'rotate-0': !(openPanel === 5) }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <transition class="mt-2 pr-12" 
                    enter-class="duration-150 ease-out" 
                    enter-active-class="opacity-0 scale-95" 
                    enter-to-class="opacity-100 scale-100" 
                    leave-class="duration-100 ease-in"
                    leave-active-class="opacity-100 scale-100" 
                    leave-to-class="opacity-0 scale-95" 
                    v-show="openPanel === 5" >
                      <p class="text-base leading-6 text-gray-500">
                        Cabsa permite a los usuarios pagar su póliza cargo a
                        tarjeta de crédito y débito, VISA, MASTER CARD Y
                        AMERICAN EXPRESS.
                      </p>
                    </transition>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  components:{

  },
  setup() {
    const openPanel = ref(1)
    
    function changeOpenPanel(val) {
      console.log("entró al metodo, valor: " + val);
      openPanel.value = openPanel.value === val ? null : val
      console.log("open panel: " + openPanel.value);
    }

    return {
      openPanel,
      changeOpenPanel,
    }
  },
}
</script>
