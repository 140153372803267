<template>
  <loading v-show="isLoading" :enforce-focus="false" :active="true" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="false" :loader="loader">
  </loading>
  <div v-show="!isLoading" style="min-height: 640px;">
    <div
      class="h-screen flex overflow-hidden bg-white"
      x-data="{ sidebarOpen: false }"
      keydown.window.escape="sidebarOpen = false"
    >
      <dashboard-side-bar-menu :key="keySideBarDesktop" />
      <div
        x-init="$el.focus()"
        class="flex-1 overflow-auto focus:outline-none"
        tabindex="0"
      >
        <dashboard-side-bar-menu-mobile :key="keySideBarMobile" />
        <main class="flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-50">
          <dashboard-header ref="dashboardHeader" :userInfo="userInfo" :key="keyHeader" />
          <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <!-- User Information -->
            <div
              class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4"
            >
              <div class="w-full max-w-4xl mx-auto my-9">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3 mb-10 text-center">
                    
                    <img v-if="!isCameraOpen" :class="{'animate-pulse': loadingImage }" class="hidden h-40 w-40 bg-gray-300 mx-auto rounded-full flex-shrink-0 sm:block focus:ring-2 focus:ring-blue-600" :src="(photo == '')? Img: photo" alt="user_image">
                    
                    <div
                      v-if="isCameraOpen"
                      v-show="!isLoading"
                      class="camera-box"
                      :class="{ flash: isShotPhoto }"
                    >
                      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

                      <video
                        v-show="!isPhotoTaken"
                        ref="camera"
                        :width="190"
                        :height="150"
                        autoplay
                        class="mx-auto rounded border-4"
                      ></video>

                      <canvas
                        v-show="isPhotoTaken"
                        id="photoTaken"
                        ref="canvas"
                        :width="210"
                        :height="150.5"
                        class="mx-auto"
                      ></canvas>
                    </div>

                    <button @click="takePhoto" class="my-3 cursor-pointer btn btn-red max-w-sm inline-flex items-center" v-if="isCameraOpen">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      Tomar foto
                    </button>
                   
                    <div class="camera-button my-3">
                     
                      <div>

                        <button @click="toggleCamera" class="cursor-pointer text-grey-darkest font-normal py-2 px-4 rounded inline-flex items-center" v-if="!isCameraOpen">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                          </svg>
                          Actualizar foto
                        </button>

                        <button
                        class="cursor-pointer text-grey-darkest font-normal py-2 px-4 rounded inline-flex items-center"
                        :class="{ 'is-primary': !isCameraOpen, 'is-danger': isCameraOpen }"
                        @click="toggleCamera" v-else>Cancelar</button>
                      </div>
                    </div>
                  
                    </div>
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Nombres
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="Nombres"
                      v-model="formPersonalInfo.nombres"
                    />

                    <div class="input-errors" v-for="error of v1$.nombres.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>
                </div> 

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Apellido paterno
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="Apellido paterno"
                      v-model="formPersonalInfo.apellido_paterno"
                    />
                    <div class="input-errors" v-for="error of v1$.apellido_paterno.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Apellido materno
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-first-name"
                      type="text"
                      placeholder="Apellido materno"
                      v-model="formPersonalInfo.apellido_materno"
                    />
                    <div class="input-errors" v-for="error of v1$.apellido_materno.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-state"
                    >
                      Teléfono
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-telephone"
                      type="telephone"
                      placeholder="644 00 0000"
                      v-model="formPersonalInfo.telefono"
                    />
                    <div class="input-errors" v-for="error of v1$.telefono.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Email
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-email"
                      type="email"
                      placeholder="Cabsa@Cabsa.com.mx"
                      v-model="formPersonalInfo.email"
                    />
                    <div class="input-errors" v-for="error of v1$.email.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3 md:px-1 lg:px-3  xl:px-3 mx-auto items-center text-center">
                    <button
                      class="btn-save inline-flex items-center px-3 py-2 text-white border-4 rounded mx-auto shadow-md"
                      @click="submitFormPersonalInfo"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                      </svg>
                      Actualizar información
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- End Form 1 -->

            <!-- Update password -->
            <div
              class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4 my-8"
            >
              <div class="w-full max-w-4xl mx-auto my-9">
                <div class="flex flex-wrap -mx-3 mb-6">

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Contraseña
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-password"
                      type="password"
                      placeholder="******************"
                      v-model="formPassword.password"
                    />
                    <div class="input-errors" v-for="error of v2$.password.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Confirmar contraseña
                    </label>
                    <input
                      class="block w-full py-3 px-4 mb-3 leading-tight form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                      id="grid-password-confirm"
                      type="password"
                      placeholder="******************"
                      v-model="formPassword.passwordConfirm"
                    />
                    <div class="input-errors" v-for="error of v2$.passwordConfirm.$errors" :key="error.$uid">
                      <div class="error-msg text-red-400">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                 <div class="w-full max-w-4xl mx-auto my-9 text-center">
                  <button
                      class="btn-save inline-flex items-center px-3 py-2 text-white border-4 rounded mx-auto shadow-md"
                      @click="submitFormPassword"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                      </svg>
                      Actualizar contraseña
                  </button>
                </div>
              
              </div>
            </div>
            <!-- End Update password -->
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardSideBarMenu from "@/components/dashboard/DashboardSideBarMenu";
import DashboardSideBarMenuMobile from "@/components/dashboard/DashboardSideBarMenuMobile";
import DashboardHeader from "@/components/dashboard/DashboardHeader";

import Swal from 'sweetalert2';
import { reactive, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, sameAs, minLength, maxLength } from "@vuelidate/validators";

import { ref, onMounted } from "@vue/runtime-core";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import User from '@/classes/User';
import { getUserAuthInfo } from '@/helpers/auth.js';
import { hasCharactersNumbers, hasSpecialcharacters, hasLetters } from '@/helpers/text.js';
import Img from '@/assets/images/dashboard/circle_transparent.png';

export default {

  setup(){
    const loadingImage = ref(true);
    const keyHeader = ref(Math.random());
    const keySideBarDesktop = ref(Math.random());
    const keySideBarMobile = ref(Math.random());
    
    const dashboardHeader = ref(null);

    const formPersonalInfo = reactive({
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      email: "",
      telefono: ""
    });

    const formPassword = reactive({
      actualPassword: "",
      password: "",
      passwordConfirm: ""
    });
  
    const isCameraOpen = ref(false);
    const isPhotoTaken = ref(false);
    const isShotPhoto = ref(false);
    const isLoading = ref(true);
    const link = ref("#");
    const photo = ref("");
  
    const messageError = {
      "required": "Campo requerido",
      "email": "Email invalido",
      "passwordMinLenght": "La contraseña debe tener al menos 6 caracteres",
      "passwordConfirm": "El valor debe ser igual al otro valor",
      "minLength": "El campo debe tener al menos 2 caracteres",
      "maxLength": "El campo debe tener máximo 20 caracteres",
      "maxTelephoneLength": "El campo debe tener máximo 15 dígitos",
      "invalidSpecialCharacters": "El campo no debe contener caracteres especiales",
      "invalidNumericCharacters": "El campo no debe contener numeros",
      "alphabeticCharacters": "El campo no debe contener letras"
    };

    const rulesForm1 = {
      nombres: { required: helpers.withMessage(messageError.required, required), minLength:  helpers.withMessage(messageError.minLength, minLength(2)), maxLength: helpers.withMessage(messageError.maxLength, maxLength(20)), hasCharactersNumbers:  helpers.withMessage(messageError.invalidNumericCharacters , hasCharactersNumbers), hasSpecialcharacters: helpers.withMessage(messageError.invalidSpecialCharacters , hasSpecialcharacters) },
      apellido_paterno: { required: helpers.withMessage(messageError.required, required), minLength:  helpers.withMessage(messageError.minLength, minLength(2)), maxLength: helpers.withMessage(messageError.maxLength, maxLength(20)), hasCharactersNumbers:  helpers.withMessage(messageError.invalidNumericCharacters , hasCharactersNumbers), hasSpecialcharacters: helpers.withMessage(messageError.invalidSpecialCharacters , hasSpecialcharacters) },
      apellido_materno: { required: helpers.withMessage(messageError.required, required), minLength:  helpers.withMessage(messageError.minLength, minLength(2)), maxLength: helpers.withMessage(messageError.maxLength, maxLength(20)), hasCharactersNumbers:  helpers.withMessage(messageError.invalidNumericCharacters , hasCharactersNumbers), hasSpecialcharacters: helpers.withMessage(messageError.invalidSpecialCharacters , hasSpecialcharacters) },
      email: { required: helpers.withMessage(messageError.required, required) , email: helpers.withMessage(messageError.email, email) },
      telefono: { required: helpers.withMessage(messageError.required, required), hasSpecialcharacters: helpers.withMessage(messageError.invalidSpecialCharacters , hasSpecialcharacters), maxLength: helpers.withMessage(messageError.maxLength, maxLength(15)), hasLetters: helpers.withMessage(messageError.alphabeticCharacters, hasLetters)  }
    }

    const v1$ = useVuelidate(rulesForm1, formPersonalInfo);

    const rulesForm2 = computed(() => {
      return {
        password: { required: helpers.withMessage(messageError.required, required), minLength:  helpers.withMessage(messageError.passwordMinLenght, minLength(6))   },
        passwordConfirm: { sameAs: helpers.withMessage(messageError.passwordConfirm, sameAs(formPassword.password)) }
      }
    })
    
    const v2$ = useVuelidate(rulesForm2, formPassword);
    
    const loadUserProfile = async () => {
      isLoading.value = true;
      User.getInfo().then(resp => {
        let request = resp; 
        console.log(resp);
        formPersonalInfo.nombres = request.persona.nombres;
        formPersonalInfo.apellido_paterno = request.persona.apellido_paterno;
        formPersonalInfo.apellido_materno = request.persona.apellido_materno;
        formPersonalInfo.email = request.email;
        formPersonalInfo.telefono = request.persona.telefono;
  
        isLoading.value = false;
      }).catch(err => {
        console.log(err.errors);
        isLoading.value = false;
      });
      

      User.getUserImage().then(resp => {
       
        if(resp.status == 200){
          photo.value = resp.config.url;
        }

        setTimeout(() => {
          loadingImage.value = false;
        }, 1000);
        
        console.log(resp);
      }).catch(err => {
        console.log(err);
        setTimeout(() => {
          loadingImage.value = false;
        }, 1000);
      });

    }

    const addImageProfile = (file, extension) => {
      isLoading.value = true;
      User.setUserImage(file, extension).then(resp => {
        keyHeader.value = Math.floor(Math.random() * 10);
        keySideBarDesktop.value = Math.floor(Math.random() * 10);
        keySideBarMobile.value = Math.floor(Math.random() * 10);
        console.log("Imagen agregada en perfil:",resp);
      }).catch(err => {
        console.log(err);
      });
    }

    onMounted(() => {
      loadUserProfile();
    });

    return {
      formPersonalInfo,
      v1$,
      formPassword,
      v2$,
      isCameraOpen,
      isPhotoTaken,
      isShotPhoto,
      isLoading,
      link,
      photo,
      Loading,
      addImageProfile,
      dashboardHeader,
      keyHeader,
      keySideBarDesktop,
      keySideBarMobile,
      Img,
      loadingImage
    };
  },

  components: {
    DashboardSideBarMenu,
    DashboardSideBarMenuMobile,
    DashboardHeader,
    Loading
  },

  methods: {
    getDataFromImageBase64(image){

      if(image== "" || typeof image == 'undefined'){
        console.log('not accepted image!');
        return {
          "extension": null,
          "file": null
        };
      }

      var info = image.replace("data:","").split(";");
      const infoImage = info[1].split(",");

      if(infoImage[0] != "base64"){
        console.log('invalid format!');
        return {
          "extension": null,
          "file": null
        };
      }

      return {
        "extension": info[0].replace('image/',''),
        "file": infoImage[1]
      };
    },
    submitFormPersonalInfo() {
      this.isLoading = true;
      this.v1$.$validate();
      if (!this.v1$.$error) {
        console.log("Success");
        
        User.updateUserInfo(
          this.formPersonalInfo.nombres,
          this.formPersonalInfo.apellido_paterno,
          this.formPersonalInfo.apellido_materno, 
          this.formPersonalInfo.email,
          this.formPersonalInfo.telefono
          
        ).then(resp => {
          this.isLoading = false;

          Swal.fire({
            title: "Actualización exitosa",
            text: "Se ha actualizado la información de su perfil correctamente",
            icon: "success",
          });

          const usuario = getUserAuthInfo();

          usuario.persona.nombres = this.formPersonalInfo.nombres ;
          usuario.persona.apellido_paterno = this.formPersonalInfo.apellido_paterno ;
          usuario.persona.apellido_materno = this.formPersonalInfo.apellido_materno ; 
          usuario.email = this.formPersonalInfo.email ;
          usuario.persona.telefono = this.formPersonalInfo.telefono ; 
          
          sessionStorage.setItem('login', JSON.stringify(usuario));

          this.keyHeader = Math.random();
          this.keySideBarDesktop = Math.random();
          this.keySideBarMobile = Math.random();

          console.log(resp);
        }).catch(err => {
          this.isLoading.value = false;
          Swal.fire({
            title: "Actualización fallida",
            text: "Ha ocurrido un problema, por favor intente más tarde",
            icon: "error",
          });
          console.log(err);
        });

      } else {
        this.isLoading = false;
        console.log("Failded");
      }
    },

    submitFormPassword() {
      
      this.v2$.$validate();
      if (!this.v2$.$error) {
        this.isLoading = true;
       
        User.changePasswordDashboard(this.formPassword.password, this.formPassword.passwordConfirm).then(res => {
          
          this.isLoading = false;
          Swal.fire({
            title: "Actualización exitosa",
            text: "Se actualizó la contraseña correctamente",
            icon: "success",
          });

          this.formPassword.password = "";
          this.formPassword.passwordConfirm = "";
          console.log(res);
        }).catch(err => {
          this.isLoading = false;

          Swal.fire({
            title: "Actualización fallida",
            text: "Ha ocurrido un problema, por favor intente más tarde",
            icon: "error",
          });
          
          console.log(err);
        });
        console.log("Success");
      } else {
        console.log("Failded");
      }
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
          Swal.fire({
            title: "Aviso",
            text: "Puede que el navegador no sea compatible o que haya algún error.",
            icon: "failed",
          });
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
      this.isLoading = false;
    },

    takePhoto() {
      if (!this.isPhotoTaken) {

        const FLASH_TIMEOUT = 20;

        setTimeout(() => {
          this.isCameraOpen = false;
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 210, 150.5);

      var canvas = document.getElementById('photoTaken');
      var dataURL = canvas.toDataURL();
      this.photo = dataURL;
      
      const imgBase64 = this.getDataFromImageBase64(dataURL);
      this.addImageProfile(imgBase64.file, imgBase64.extension);

      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      
      User.getUserImage().then(resp => {
        const user = getUserAuthInfo();

        if(resp.status == 200){
          user.image = `${ resp.config.url } ${ user.image }&cache${ Math.floor(Math.random() * 1000) }=${ Math.floor(Math.random() * 100) }`;
          sessionStorage.setItem('login', JSON.stringify(user));

          this.keyHeader.value = Math.floor(Math.random() * 10);
          this.keySideBarDesktop.value = Math.floor(Math.random() * 10);
          this.keySideBarMobile.value = Math.floor(Math.random() * 10);
        }

      }).catch(err => {
        console.log(err);
      });
      
      
      this.stopCameraStream();
    }
  },
};
</script>
<style>
  .toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68d391;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68d391;
  }
  .btn-red:hover{
    background: #EE5835;
  }
  video{
    border-color: #EE5835;
  }
  .btn-save {
    background: #EE5835;
    border-color: #EE5835;
  }
  .btn-save:hover {
    background: #e9866e;
    border-color: #e9866e;
    color: white;
  }
</style>