import axios from "axios";
var multicr_api = process.env.VUE_APP_MULTICR;
var config = {
 'Content-Type': 'application/json'
};
export default class Roles{
UrlParam=null;
    constructor(UrlParam){
        this.UrlParam=UrlParam;
    }

    async getAllRoles(){
        const token=(sessionStorage.getItem('login'))? JSON.parse(sessionStorage.getItem('login')).token: '';
        return await axios.get(multicr_api+this.UrlParam+"?token_auth="+token, config);
    }
}