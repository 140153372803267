<template lang="en">
<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
  <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
  </loading>
  <div class="mx-auto w-full max-w-sm lg:w-96">
    <div>
      <router-link to="/">
          <img
            class="w-20"
            src="@/assets/images/logo.png"
            alt="Workflow"
        /></router-link>
      <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
        Registra tu cuenta
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w">Completa los siguientes campos requeridos para realizar tu registro.</p>
    </div>
    <div class="mt-8">
      <div>
        <div class="mt-6">
          <form @submit="registerUser($event)" id="form_register" class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Nombres</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="input_names" type="text" v-model="names" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <span>{{ namesError }}</span>
            </div>

            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Apellido Paterno</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="input_paternalName" type="text" v-model="paternalName"  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <span>{{ paternalNameError }}</span>
            </div>

            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Apellido Materno</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="input_maternalName" type="text" v-model="maternalName" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <span>{{ maternalNameError }}</span>
            </div>
            <div>
              <label for="sexo" class="block text-sm font-medium leading-5 text-gray-700">Sexo</label>
              <div class="mt-1 rounded-md shadow-sm">
                <select lang="en" translate="no" id="select_gender"  v-model="gender" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                  <option disabled value="">Seleccionar</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino </option>
                </select>
              </div>
              <span>{{ genderError }}</span>
            </div>
            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Teléfono o Celular</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="input_cellphone" type="text" v-model="cellphone"  v-maska="'### ###-####'" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <span>{{ cellphoneError }}</span>
            </div>
            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Correo electrónico</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="input_email" type="email" v-model="email"   class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <span>{{ emailError }}</span>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium leading-5 text-gray-700"> Contraseña </label>
              <div class="rounded-md shadow-sm">
                <input id="input_password" type="password" v-model="password"  class="appearance-none w-10/12 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <span tabIndex="-1" class="input-group-addon" style="background-color: #ffffff">
                  <button type="button" id="btn_show_password" tabIndex="-1" v-on:click="showPassword()" class="appearance-none w-2/12 px-4 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                    <div tabIndex="-1" id="divImageHidePasswordOn">
                      <img id="imageHidePasswordOn" src="@/assets/visibility-off.svg" width="20" height="20" s />
                    </div>
                    <div tabIndex="-1" id="divImageHidePasswordOff" class="invisible">
                      <img tabIndex="-1" id="imageHidePasswordOff" src="@/assets/visibility-on.svg" width="0" height="0" />
                    </div>
                  </button></span>
              </div>
              <span>{{ passwordError }}</span>
            </div>

            <div class="form-group">
              <label for="password" class="block text-sm font-medium leading-5 text-gray-700">Confirmar contraseña:</label>
              <div class="input-group">
                <input type="password" id="input_confirmPassword"  v-model="confirmPassword"  class="appearance-none w-10/12 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                <span tabIndex="-1" class="input-group-addon" style="background-color: #ffffff">
                  <button type="button" id="btn_show_confirm_password" tabIndex="-1" v-on:click="showConfirmPassword()" class="appearance-none w-2/12 px-4 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                    <div tabIndex="-1" id="divImageHidePasswordConfirmOn">
                      <img id="imageHidePasswordConfirmOn" src="@/assets/visibility-off.svg" width="20" height="20" s />
                    </div>
                    <div tabIndex="-1" id="divImageHidePasswordConfirmOff" class="invisible">
                      <img tabIndex="-1" id="imageHidePasswordConfirmOff" src="@/assets/visibility-on.svg" width="0" height="0" />
                    </div>
                  </button></span>
              </div>
              <span>{{ confirmPasswordError }}</span>
            </div>

            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button type="submit" id="btn_register" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                  Registrarme
                </button>
              </span>
            </div>
          </form>
          <br><br><router-link to="/login">
            <button id="btn_regresar" class="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
              Volver
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  useField,
  useForm,
} from 'vee-validate';
import User from "@/classes/User.js"
import {
  ref
  //onMounted
} from 'vue';
//import axios from "axios";
import Swal from 'sweetalert2';
import {
  useRouter
} from 'vue-router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  maska
} from 'maska'
export default {
  name: "CreateAccount",
  components: {
    Loading
  },
  directives: {
    maska
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const loader = ref('spinner');
    
    const errors = ref([]);
    const isValidateLastname = ref(false);

    //validations
    // Define a validation schema
    const simpleSchema = {
      email(value) { //^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$
        // if the field is empty
        if (!value) {
          return 'Campo requerido';
        }
        // if the field is not a valid email
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          return 'El formato no coincide con un correo válido';
        }
        // All is good
        return true;
      },
      password(value) {
        if (!value) {
          return "Campo requerido";
        }
        if (value.length < 8) {
          return "El campo debe tener minímo 8 caracteres";
        }
        if (value.length > 30) {
          return "El campo debe tener máximo de 30 caracteres";
        }
        // All is good
        return true;
      },
      confirmPassword(value) {
        if (!value) {
          return "Campo requerido";
        }
        if (value.length < 8) {
          return "El campo debe tener minímo 8 caracteres";
        }
        if (value.length > 30) {
          return "El campo debe tener máximo de 30 caracteres";
        }
        // All is good
        return true;
      },
      names(value) {
        if (!value) {
          return "Campo requerido";
        }
        if (value.length < 3) {
          return "El campo debe tener minímo 3 caracteres";
        }
        if (value.length > 30) {
          return "El campo debe tener máximo de 30 caracteres";
        }
        if (!/^[A-Za-zéñúóáíáÍÑÁÉÓÚ]+$/i.test(value)) {
          return 'El campo debe tener sólo letras';
        }
        // All is good
        return true;
      },
      
      paternalName(value) {
        if (value) {  
          if (value.length < 3) {
            return "El campo debe tener minímo 2 caracteres";
          }
          if (value.length > 30) {
            return "El campo debe tener máximo de 30 caracteres";
          }
          if (!/^[A-Za-zéñúóáíáÍÑÁÉÓÚ.\s]+$/i.test(value)) {
            return 'El campo debe tener sólo letras';
          }
         }
        // All is good
        return true;
      },
      maternalName(value) {
        if (value) {  
          if (value.length < 4) {
            return "El campo debe tener minímo 3 caracteres";
          }
          if (value.length > 30) {
            return "El campo debe tener máximo de 30 caracteres";
          }
          if (!/^[A-Za-zéñúóáíáÍÑÁÉÓÚ.\s]+$/i.test(value)) {
            return 'El campo debe tener sólo letras';
        }
        }
        // All is good
        return true;
      },
      gender(value) {
         if (!value) {
          return "Campo requerido";
        }
        // All is good
        return true;
      }, 
      cellphone(value) { //pattern="[0-9]{3}[ ][0-9]{3}[-][0-9]{4}"
         if (!value) {
          return "Campo requerido";
        }
        if (value.length == 10) {
          return "El campo debe tener 10 caracteres";
        }
        // All is good
        return true;
      },
    };
    // Create a form context with the validation schema
    useForm({
      validationSchema: simpleSchema,
    });

    // No need to define rules for fields
    const { value: email, errorMessage: emailError } = useField('email');
    const { value: password="", errorMessage: passwordError } = useField('password');
    const { value: confirmPassword, errorMessage: confirmPasswordError } = useField('confirmPassword');
    const { value: names, errorMessage: namesError } = useField('names');
    const { value: paternalName, errorMessage: paternalNameError } = useField('paternalName');
    const { value: maternalName, errorMessage: maternalNameError } = useField('maternalName');
    const { value: gender, errorMessage: genderError } = useField('gender');
    const { value: cellphone, errorMessage: cellphoneError } = useField('cellphone');
 
    async function registerUser(e) {
      e.preventDefault();
      if ( email.value  && password.value && names.value  && paternalName.value && maternalName.value && gender.value && confirmPassword.value && cellphone.value) {
        isLoading.value = true;
        let procesar = await validateFilds();
        if (errors.value.length == 0) {
          if (procesar) {
            const user = new User(names.value, paternalName.value, maternalName.value, gender.value, removeMask(cellphone.value), email.value, password.value, "Cliente");
            user.save()
              .then(() => {
                Swal.fire({
                  title: "Aviso",
                  text: "Se registró correctamente. Para activar su cuenta deberá de entrar a la liga que se le envió al correo proporcionado, si no lo encuentra favor de buscar en correo no deseado.",
                  icon: "success",
                }).then(function () {
                  router.push({
                    name: "ConfirmAccount"
                  });
                });
              })
              .catch((error) => {
                let messageError = getErrors(error.response.data.errores);
                Swal.fire({
                  title: "Aviso",
                  html: `<span style="font-weight: bold; color:red">${messageError == undefined || messageError.length < 5 ? "No fue posible registrar la cuenta, contacte un asesor." : messageError}</span>`,
                  icon: "error",
                });

              }).finally(() => {
                isLoading.value = false;
              });
          }
        } else {
          isLoading.value = false;
          Swal.fire({
            title: "Aviso",
            text: errors.value,
            icon: "warning",
          });
        }
      }
      else{
        Swal.fire({
          title: "Aviso",
          text: 'Los campos son requeridos',
          icon: "warning"
        });
      }
    }
    async function validateFilds() {
      errors.value = [];
      if (paternalName.value == "" || maternalName.value == "") {
        await validateLastname();
        return isValidateLastname.value;
      } else {
        if (         
          password.value.includes(" ") == true ||
          confirmPassword.value.includes(" ") == true
        ) {
          errors.value.push("La contraseña no puede contener espacios");
          return false;
        }
        if (password.value != confirmPassword.value) {
          errors.value.push("Las contraseñas son diferentes");
          return false;
        }
        return true;
      }
    }
    async function validateLastname() {
      isLoading.value = false;
      let lastname = paternalName.value == "" ? "Apellido paterno" : "Apellido materno";
      await Swal.fire({
        title: "Aviso",
        text: `Usted no ingresó ${lastname}. ¿Cuenta con él?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#FEB72B',
        confirmButtonText: "Si, Quiero registrarlo",
        cancelButtonText: "No, Continuar con registro"
      }).then((result) => {
        showValidateLastname(result.value);
      });
    }

    async function showValidateLastname(result) {
      if (result) {
        if (paternalName.value == "") {
          document.getElementById("paternalName").focus()
        } else {
          if (maternalName.value == "") {
            document.getElementById("maternalName").focus()
          }
        }
      }
      if (!result) {
        if (paternalName.value == "" && maternalName.value == "") {
          await Swal.fire({
            title: "Aviso",
            text: "Se debe capturar por lo menos un apellido",
            icon: "warning",
          });
          document.getElementById("paternalName").focus()
        } else {
          isValidateLastname.value = true;
        }
      }
    }

    function removeMask(phone) {
      if (phone.includes("(")) {
        phone = phone.replace("(", "");
      }
      if (phone.includes(")")) {
        phone = phone.replace(")", "");
      }
      if (phone.includes("-")) {
        phone = phone.replace("-", "");
      }
      if (phone.includes(" ")) {
        phone = phone.replace(" ", "");
      }
      return phone;
    }

    function getErrors(errors) {
      let messageError = "";
      for (const key in errors) {
        let property = key.split(".");
        let fieldAnswer = property[1] ? property[1] : key;
        let error = errors[key];

        messageError += `${transformNameFields(fieldAnswer)}: ${transformMessages(error[0])} ` + "<br/>";
      }

      return messageError;
    }

    function transformNameFields(field) {
      let nameField = "";
      switch (field) {
        case "name":
          nameField = "Nombres";
          break;
        case "paternal_name":
          nameField = "Apellido paterno";
          break;
        case "maternal_name":
          nameField = "Apellido materno";
          break;
        case "email":
          nameField = "Correo";
          break;
        default:
          nameField = "Se presentó un error el crear la cuenta";
          break;
      }
      return nameField;
    }

    function transformMessages(error) {
      let descriptionError = "";
      switch (error) {
        case "ya existe":
          descriptionError = "ya registrado, favor de rectificar.";
          break;
        default:
          descriptionError = error;
          break;
      }
      return descriptionError;
    }

    function showPassword() {
      var tipo = document.getElementById("input_password");
      if (tipo.type == "password") {
        tipo.type = "text";
        document.getElementById("divImageHidePasswordOn").className = "invisible";
        document.getElementById("imageHidePasswordOn").width = "0";
        document.getElementById("imageHidePasswordOn").height = "0";
        document.getElementById("divImageHidePasswordOff").className = "visible";
        document.getElementById("imageHidePasswordOff").width = "20";
        document.getElementById("imageHidePasswordOff").height = "20";
      } else {
        tipo.type = "password";
        document.getElementById("divImageHidePasswordOn").className = "visible";
        document.getElementById("imageHidePasswordOn").width = "20";
        document.getElementById("imageHidePasswordOn").height = "20";
        document.getElementById("divImageHidePasswordOff").className = "invisible";
        document.getElementById("imageHidePasswordOff").width = "0";
        document.getElementById("imageHidePasswordOff").height = "0";
      }
    }

    function showConfirmPassword() {
      var tipo = document.getElementById("input_confirmPassword");
      if (tipo.type == "password") {
        tipo.type = "text";
        document.getElementById("divImageHidePasswordConfirmOn").className = "invisible";
        document.getElementById("imageHidePasswordConfirmOn").width = "0";
        document.getElementById("imageHidePasswordConfirmOn").height = "0";
        document.getElementById("divImageHidePasswordConfirmOff").className = "visible";
        document.getElementById("imageHidePasswordConfirmOff").width = "20";
        document.getElementById("imageHidePasswordConfirmOff").height = "20";
      } else {
        tipo.type = "password";
        document.getElementById("divImageHidePasswordConfirmOn").className = "visible";
        document.getElementById("imageHidePasswordConfirmOn").width = "20";
        document.getElementById("imageHidePasswordConfirmOn").height = "20";
        document.getElementById("divImageHidePasswordConfirmOff").className = "invisible";
        document.getElementById("imageHidePasswordConfirmOff").width = "0";
        document.getElementById("imageHidePasswordConfirmOff").height = "0";
      }
    }

    return {
      email,
      password,
      isLoading,
      loader,
      confirmPassword,
      paternalName,
      maternalName,
      names,
      gender,
      cellphone,
      registerUser,
      showPassword,
      showConfirmPassword,
      
      emailError,
      passwordError,
      confirmPasswordError,
      namesError,
      paternalNameError,
      maternalNameError,
      genderError,
      cellphoneError,
    };
  }
}
</script>
<style>
span {
  @apply text-sm text-red-500;
}
</style>
