<template>
<div class="flex justify-center items-center mt-8">
  <div class="max-w-xl w-full">
    <!-- Step 1 Quoter presentation -->
    <div class="flex justify-center">
      <div class="grid grid-cols-1">
        <div>
          <div class="container">
            <div class="grid grid-cols-2">
              <!-- <img class="heaven animate-bounce"  src="@/assets/images/ilustraciones/img-sitio-anter_cotizacion_globo.svg" fill="none" stroke="currentColor">  -->
              <!-- <img class="eye em"  src="@/assets/images/ilustraciones/img-sitio-anter-TEO.svg" fill="none" stroke="currentColor">     -->
            </div>
          </div>
        </div>
        <div>
          <span class="ex1 text-2xl tracking-tight leading-7 sm:text-2xl sm:leading-none md:text-2xl lg:text-2xl xl:text-2xl text-gray-600">
            Ahora que ya me conoces, me gustaría hacerte algunas preguntas para conocerte mejor y ayudarte a encontrar el seguro ideal para ti.
          </span>
        </div>
        <div>
          <span class="w-full inline-flex rounded-md shadow-sm">                  
            <button type="button" id="btn_next" @click="next()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
              Siguiente 
            </button>
          </span>
        </div>
      </div>
    </div>
    <p class="text-xs text-gray-400 text-center mt-4">No te preocupes, la cotización es sin ninguna obligación ni compromiso a aceptarla</p>
  </div>
  <imagenglobo/>
</div>
</template>

<script>

import {
  useRouter
} from 'vue-router'
import {
  inject,
  onMounted
} from "vue";


export default {
  name: "VirtualAssitant",


  setup() {
    const router = useRouter();
    const aspects = inject('aspects');
    const arrayAnswers = inject('arrayAnswers');
    const categories = inject('categories');
    const step = inject('step');

    onMounted(() => {
      arrayAnswers.value = [];
      localStorage.clear();
      aspects.value = [];
      categories.value = [];
      
      step.value = 1;
      
    });

    function next() {
      step.value++;
      router.push({
        name: "Products"
      });
    }
    return {
      next
    }
  },
}
</script>
<style>
.eye{
  width:75%;
   margin-left:-30%;
   top: 1000%;
  z-index: 1;
}

.heaven
{
  postion:absolute;
  width:50%;
  margin-left:38%;
  top:40%;
  z-index: 1;
}

p.ex1{
  margin-top: 0em;
  margin-bottom: .2em;
}
img.im{
  margin-top: 0em;
  margin-bottom: 0em;
}
</style>