<template>
  <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
    <nav class="relative flex items-center justify-between sm:h-10">
      <div class="flex items-center flex-1">
        <div class="flex items-center justify-between w-full md:w-auto">
          <a href="/" aria-label="Home">

            <img class="h-20" src="@/assets/images/logo.png" alt="Logo"/>
          </a>
          <div class="-mr-2 flex items-center md:hidden">
            <button type="button" @click="openMenu" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <nav-menu/>
    </nav>
  </div>
  <nav-menu-mobile />
</template>
<script>
import NavMenu from '@/components/common/NavMenu';
import NavMenuMobile from '@/components/common/NavMenuMobile';
import { ref } from '@vue/reactivity';
import { provide } from '@vue/runtime-core';

export default {
  components: {
    NavMenu,
    NavMenuMobile
  },
  setup() {
    const openNavBar = ref(false);
    provide('openNavBar', openNavBar);
    
    function openMenu() {
      openNavBar.value = true
    }
    return {
      openNavBar,
      openMenu,
    }
  },
};
</script>
