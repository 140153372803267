<template>
  <div class="relative max-w-xl mx-auto">
    <contact-pattern-right />
    <contact-pattern-left />
    <div class="mt-12">
      <Form :validation-schema="schema" v-slot="{ errors }" @submit="onSubmit" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div>
          <label for="firstName" class="block text-sm font-medium leading-5 text-gray-700" :class="{ 'text-red-600': errors.firstName }">Nombre</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <Field name="firstName" v-model="firstName" id="firstName" :class="{ 'bg-red-100 border-red-400': errors.firstName }" class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
            <div class="text-red-600">{{errors.firstName}}</div>
          </div>
        </div>
        <div>
          <label for="lastName" class="block text-sm font-medium leading-5 text-gray-700" :class="{ 'text-red-600': errors.lastName }">Apellido</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <Field name="lastName" v-model="lastName" id="lastName" :class="{ 'bg-red-100 border-red-400': errors.lastName }" class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
            <div class="text-red-600">{{errors.lastName}}</div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="company" class="block text-sm font-medium leading-5 text-gray-700">Empresa (opcional)</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <Field  name="company" id="company" v-model="company" class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
            <div class="text-red-600">{{errors.company}}</div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="email" class="block text-sm font-medium leading-5 text-gray-700" :class="{ 'text-red-600': errors.email }">Correo electrónico</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <Field name="email" id="email" type="email" v-model="email"  :class="{ 'bg-red-100 border-red-400': errors.email }" class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"/>
            <div class="text-red-600">{{errors.email}}</div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="phoneNumber" class="block text-sm font-medium leading-5 text-gray-700" :class="{ 'text-red-600': errors.phoneNumber }">Teléfono</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 flex items-center">
              <Field as="select" aria-label="Country" value="MX" name="country" class="form-select h-full py-0 pl-4 pr-8 border-transparent bg-transparent text-gray-500 transition ease-in-out duration-150">
                <option>MX</option>
              </Field>
            </div>
            <Field name="phoneNumber" id="phoneNumber" v-model="phoneNumber" :class="{ 'bg-red-100 border-red-400': errors.phoneNumber }" class="form-input py-3 px-4 block w-full pl-20 transition ease-in-out duration-150"/>
            
          </div>
          <div class="text-red-600">{{errors.phoneNumber}}</div>
        </div>
        <div class="sm:col-span-2">
          <label for="message" class="block text-sm font-medium leading-5 text-gray-700" :class="{ 'text-red-600': errors.message }">Mensaje</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <Field name="message" id="message" type="text-area" v-model="message" :class="{ 'bg-red-100 border-red-400': errors.message }" class="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150" />
            <div class="text-red-600">{{errors.message}}</div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <div class="flex items-start">
            <div class="flex-shrink-0">
                <Field
                  name="acceptTerms" 
                  v-model="acceptTerms"  
                  type="checkbox"
                  id="acceptTerms" 
                  value="false"
                  class="relative inline-flex flex-shrink-0 h-6 w-11 rounded-full cursor-pointer transition-colors ease-in-out "
                  :class="{ 'bg-red-100 border-red-400': errors.acceptTerms }" />
            </div>
            <div class="ml-3">
              <p class="text-base leading-6 text-gray-500">
                Al seleccionar la casilla, estàs de acuerdo con nuestro <a href="/privacy" class="font-medium text-gray-700 underline">aviso de privacidad</a> y <a href="#" class="font-medium text-gray-700 underline">tèrminos y condiciones</a>.
              </p>
              <div class="text-red-600">{{errors.acceptTerms}}</div>
            </div>
          
          </div>
        </div>
        <div class="sm:col-span-2">
          <span class="w-full inline-flex rounded-md shadow-sm">
            <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
              Platiquemos
            </button>
          </span>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>

import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

import ContactPatternLeft from "@/components/contact/ContactPatternLeft";
import ContactPatternRight from "@/components/contact/ContactPatternRight";
import { ref } from "@vue/reactivity";
import axios from "axios";
import swal from 'sweetalert2';


export default {
  name: 'ContactFormComponent',
  components: {
    Form,
    Field,
    ContactPatternLeft,
    ContactPatternRight,
  },
  setup() {

    const firstName = ref('');
    const lastName = ref('');
    const company = ref('');
    const email = ref('');
    const phoneNumber = ref('');
    const message = ref('');
    const acceptTerms = ref(true);

    const schema = Yup.object().shape({
      firstName: Yup.string()
        .required('El campo nombre es obligatorio'),
      lastName: Yup.string()
        .required('El campo apellido es obligatorio'),
      email: Yup.string()
        .required('El campo Correo electrónico es obligatorio')
        .email('Formato correo electrónico invalido'),
      phoneNumber: Yup.number()
        .typeError("El teléfono no puede incluir letras")
        .positive('Formato de teléfono incorrecto')
        .integer('Formato de teléfono incorrecto')
        .min(10, 'Formato de teléfono incorrecto')
        .required('El campo teléfono es obligatorio'),
      message: Yup.string()
        .required('El campo mensaje es obligatorio'),
      acceptTerms: Yup.boolean()
        .oneOf([false,'false'],"Debe aceptar los terminos para poder enviar el formulario")
        .typeError("Debe aceptar los terminos para poder enviar el formulario")
        .required('Debe aceptar los terminos para poder enviar el formulario')
    });

    function encryptMessage(mensaje) {
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );

      var data = {
        html: mensaje,
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "vM22%!SmnjNV";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      var tokenMessage = encodedHeader + "." + encodedPayload + "." + signature;
      return tokenMessage;
    }

    function sendForm() {
      let local_message = `
      <html lang="es">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Cabsa</title>
        <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"/>
      </head>
      <body class="bg-orange-500">
        <div class="shadow-lg pb-6 bg-white w-full">
          <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
            <div class="flex items-center justify-between sm:h-10">
              <div class="flex items-center">
                <div class="flex items-center pt-5 ">
                  <img class="w-20" src="@/assets/images/logo.png" alt="Logo"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <div class=" mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div class="max-w-6xl mx-auto bg-white rounded-md p-10">
                <h2 class="text-center text-3xl leading-9 text-gray-700">
                  Contacto Cabsa
                </h2>
                <div class="mt-6 border-t-2 border-orange-500 pt-6">
                  <h1 class="text-4xl text-bold">Una persona quiere ponerse en contacto</h1>
                  <p class="text-2xl">Hemos recibido un correcto desde el formulario de contato con la siguiente información</p>
                  <br><br>
                  <p class="text-2xl"><b>Nombre:</b> ${firstName.value} ${lastName.value}</p>
                  <p class="text-2xl"><b>Empresa:</b> ${company.value}</p>
                  <p class="text-2xl"><b>Teléfono:</b> ${phoneNumber.value}</p>
                  <p class="text-2xl"><b>Correo electrónico:</b> ${email.value}</p>
                  <p class="text-2xl"><b>Mensaje:</b> ${message.value}</p>
                </div>
            </div>
        </div>
      </main>
      </body>
    </html>`;

      axios
        .post(
          "https://cabsacore.com/mailer/send_email",
          {
            mail: {
              business: "CARTERA REGULAR",
              process_type: "contacto",
              title: "Nuevo mensaje de cliente",
              body_mail: encryptMessage(local_message),
              mailing_list: [{ email: "contacto@Cabsa.com.mx" }],
              // mailing_list: [{ email: "rene.yepiz@gcabsa.com.mx" }],
              urls_attachment: [],
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          if (response.status == 200) {
            swal.fire({
              icon: 'success',
              title: 'Operación correcta',
              text: 'Mensaje enviado con exito'
            }).then((result)=>{
              if(result.isConfirmed){
                firstName.value = "";
                lastName.value = "";
                company.value = "";
                email.value = "";
                phoneNumber.value = "";
                message.value = "";
                acceptTerms.value = false;

                window.location.href = 'https://Cabsa.com.mx';
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const onSubmit = () =>{
      // display form values on success
      sendForm()
    }


    return {
      schema,
      onSubmit,

      firstName,
      lastName,
      company,
      email,
      phoneNumber,
      message,
      acceptTerms
    };
  }
};
</script>
