// import Swal from "sweetalert2";
import axios from "axios";
var multicr_api = process.env.VUE_APP_MULTICR;
export default class Policy {
  role = (sessionStorage.getItem('login'))? JSON.parse(sessionStorage.getItem('login')).rol: '';
  constructor(folio, policyNumber) {
    this.folio = folio;
    this.policyNumber = policyNumber;
  }

  async get(){
    let url = "";
    let params = "";
    if(this.role == 'Administrador'|| this.role == 'Super_Administrador'){
      params = {
        folio: this.folio,
        numero_poliza: this.policyNumber,
      };
      url = multicr_api + "/poliza_pdf/obtener_por_folio_y_numero_poliza";
    }
    let options = this.setOptions(params);
    return await axios.get(url, options);
  }

  setOptions(params){
    return {
      headers: {
        "Content-Type": "Application/Json",
        Authorization: sessionStorage.getItem("login")
          ? JSON.parse(sessionStorage.getItem("login")).token
          : "",
      },
      params: params,
    };
  }

  openLink(url){
    window.open(url);
  }
}
