<template>
  <div class="mt-10 lg:mt-0" data-aos="fade-up" data-aos-anchor-placement="top-top" data-aos-anchor="#products" data-aos-delay="200">
    <p class="text-2xl tracking-tight leading-7 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-2xl lg:text-2xl xl:text-2xl">
      <span class="text-2xl tracking-tight leading-7 font-extrabold sm:text-2xl sm:leading-none md:text-2xl lg:text-2xl xl:text-2xl text-orange-500">
        Seguro de flotillas: Somos tu aliado que garantiza
      </span> 
      <br /> la protección de tus unidades 
    </p>
    <p class="mt-2 text-base leading-6 text-gray-500">
      En Cabsa sabemos lo especial y único que es tu negocio, por ello podemos ayudarte a crear 
      un plan a tu medida que cubra todas tus necesidades de seguro de transporte, buscando
      siempre las mejores condiciones y el mejor precio. 
    </p>
    <br />
    <a href="autos-empresa" class="rounded-md shadow bg-orange-500 text-white py-2 px-2">
      Ver producto
    </a>
  </div>
</template>