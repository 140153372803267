<template>
  <div class="relative mt-12 sm:mt-16 lg:mt-24" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-center" data-aos-anchor="#trigger-second-services">
    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="lg:col-start-2">
        <div class="text-base max-w-prose mx-auto">
          <div class="prose text-gray-500 text-lg">
            <h2>Beneficios de Planmed Óptimo</h2>
            <ul>
              <li>Consultas ilimitadas de atención primaria<br>(medicina general, pediatría y ginecología)</li>
              <li>Orientación Médica telefónica y domiciliaria 24/7<br>(Atención domiciliaria con costo adicional)</li>
              <li>2 servicios al año de ambulancia por emergencia<br>(Los médicos coordinarán el envío en caso de ser necesario)</li>
              <li>Consultas médicas de especialidad ilimitadas
                <br>(Medicina interna pisquiatría, cirugía general, otorrinoraringología, urología, ortepedia y traumatología)</li>
              <li>Consultas de apoyo
                <ul>
                  <li>* 6 consultas de nutrición</li>
                  <li>* 6 consultas de psicología</li>
                </ul>
              </li>
              <li>Estudios de laboratorio</li>
              <li>Terapias físicas</li>
            </ul>
            <h2>Beneficios de Planmed Híbrido</h2>
            <ul>
              <li>Consultas de atención primaria en modalidad presencial o virtual<br>(medicina general)</li>
              <li>Orientación médica telefónica 24/7</li>
              <li>Consultas de apoyo
                <ul>
                  <li>* 6 consultas de nutrición</li>
                  <li>* 6 consultas de psicología</li>
                </ul>
              </li>
              <li>Estudios de laboratorio</li>
              <li>Terapias físicas</li>
            </ul>
          </div>
          <div class="flex justify-center w-full mt-12">
            <span class="w-full inline-flex rounded-md shadow-sm">
              <a  href="https://api.whatsapp.com/send?phone=5216441559264&text=%C2%A1Hola!,%20me%20gustar%C3%ADa%20cotizar%20AXA%20Keralty,%20%20%C2%BFqu%C3%A9%20informaci%C3%B3n%20necesito?" class="w-full flex items-center justify-center px-8 py-3 border
              border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500
              hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange 
              transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 ">
                Cotizar por WhatsApp
              </a>
            </span>
            
          </div>
        </div>
      </div>

      <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
        <!-- <img class="relative mx-auto" width="490" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="100" data-aos-anchor-placement="top-center" data-aos-anchor="#trigger-second-services" src="@/assets/images/ilustraciones/ilustraciones_medico2.svg" alt=""/> -->
      </div>
    </div>
  </div>
</template>
