import axios from "axios"
import { getUserAuthInfo } from "@/helpers/auth.js";
var jsonStringData = { "proveedor" : "", "informacion" : ""};
var multicr_api = process.env.VUE_APP_MULTICR;
let config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getUserAuthInfo().token}`
  }
};
export default class BehaviorJson {

  constructor(provider, quoteParams) {
    this.provider = provider;
    this.quoteParams = quoteParams;
  }  


  async buildJsonQuote (provider) {    
    jsonStringData.proveedor = provider;
    jsonStringData.informacion = BehaviorJson.parseStringToJson(this.quoteParams);

    const response = await axios.post(multicr_api + "/autos/solicitar_cotizacion", jsonStringData, config);
    return await response;
  }


  static calculateClientAge(age_client){
    var birth = new Date(age_client.substr(0,10));
    var current  = new Date();
    var diff = current.getTime() - birth.getTime();
    
    return  Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
  }
  

  static parseStringToJson(objectString){
    while (objectString!=null && typeof objectString == "string") {
      objectString = JSON.parse(objectString);
    }
    return objectString
  }
  

  static quotationDate(){
    var current = new Date();
    return new Date(current).toISOString()
  }
  

  static expiredDate(){
    var current = new Date();
    var current_one_year = new Date(current.setFullYear(current.getFullYear() + 1));
    return new Date(current_one_year).toISOString()
  }
  
}