<template>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg max-w-prose mx-auto mb-6">
      <h1
        class="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10"
      >
        Términos y condiciones
      </h1>
      <p class="text-xl text-gray-500 leading-8">
        El Usuario manifiesta aceptación y conformidad con el presente “Aviso Legal”
        y todas sus futuras modificaciones por el hecho de utilizar el servicio que
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V., brinda a través 
        de este sitio.
      </p>
    </div>

    <div class="prose prose-lg text-gray-500 mx-auto">
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. se
        reserva el derecho a realizar cambios en el presente “Aviso Legal” sin necesidad
        de informarlo al Usuario, dejando al mismo la responsabilidad de revisar las
        modificaciones, mismas que entrarán en vigor desde el momento de su publicación.
      </p>
      <p>
        Queda expresamente señalado que el servicio de asesoría, comparación,
        simulación y cálculo de cotizaciones de seguros de auto, así como
        para el enlace con aseguradoras que presta Cabsa SEGUROS, 
        AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. se proporciona con base en 
        la información publicada por las aseguradoras de forma estimada y 
        puede presentar cambios sin previo aviso.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. no es 
        responsable de cerciorarse o comprobar la veracidad de la información 
        proporcionada por las aseguradoras ni por el cumplimiento de estas últimas 
        de los ofrecimientos presentados en sus cotizaciones.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. se reserva el
        derecho de suspender o cancelar temporal o definitivamente el acceso en 
        cualquier momento al Usuario que quebrante alguna disposición legal aplicable, 
        así como para iniciar las acciones legales correspondientes.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. proporciona
        el sitio www.Cabsa.com.mx sus contenidos como están y según disponibilidad, sin
        ninguna representación o garantía de ningún tipo, ya sea explícita o implícita.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. se deslinda 
        de toda responsabilidad sobre la veracidad de los datos personales obtenidos 
        para la prestación del servicio a través del portal www.Cabsa.com.mx
      </p>
      <p> 
        En ningún caso Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS
        S.A DE C.V. será responsable de daños resultantes de error, omisión, virus, 
        retraso o interrupción en las operaciones o el servicio,sin importar el motivo.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. no será
        responsable de las pérdidas o daños derivados de su contenido o relacionados
        de cualquier manera con sitios web de terceras partes o si se ha accedido a ellos
        mediante enlaces colocados en este sitio.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. no
        garantiza que los productos, materiales o servicios disponibles a través
        de este sitio sean legales, apropiados o disponibles para su uso fuera
        del territorio de los Estados Unidos Mexicanos, y de tener acceso a ellos
        desde estos lugares donde los mismos o su contenido no se encuentre permitido.
        Cualquier Usuario que opte por acceder a este sitio desde ubicaciones dentro o
        fuera del territorio de los Estados Unidos Mexicanos lo hace bajo su propia
        responsabilidad, por iniciativa propia y es enteramente responsable del
        cumplimiento de las leyes locales.
      </p>
      <p>
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V. informa
        que si bien hace su mejor esfuerzo a fin de mantener la calidad y servicio
        que se proporcionan, el contenido al que es posible tener acceso a través 
        del Internet, así como aquel que se puede encontrar en cualquier enlace,
        página web, entre otros, no es, ni puede ser monitoreado, evaluado ni controlado,
        por lo que desde este momento, el Usuario libera de manera absoluta y sin 
        limitación alguna a Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V.
        de cualquier tipo de responsabilidad civil, penal, administrativa,
        así como de cualquier daño patrimonial o moral, perjuicios o cualquier
        otro que pudiera emanar del uso de los Servicios y de la información
        disponible.
      </p>
      <p>
        Los Usuarios podrán enviar cualquier comentario o sugerencia a 
        Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V.
        a través del portal www.Cabsa.com.mx y/o al correo electrónico: contacto@Cabsa.com.mx
      </p>
    </div>
  </div>
</template>
