<template>
  <div class="py-16 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div id="trigger-second-services" class="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="text-base max-w-prose mx-auto">
          <p class="leading-6 text-orange-500 font-semibold tracking-wide uppercase">
            Protegerte es lo más importante
          </p>
          <h1 class="mt-2 mb-8 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
            Expertos en Seguros
          </h1>
          <div class="prose text-gray-500 text-lg">
            <p>
              Un seguro de auto es un respaldo en caso de cualquier imprevisto
              que puedas ocasionar o ser participe mientras conduces,
              diariamente en el país se registran una gran cantidad de
              accidentes, por ello es importante contar con una protección en
              caso de algún daño material, robo, gastos médicos de los ocupantes
              del vehículo y los daños a terceros que podrías ocasionar.
            </p>

            <p>
              Contamos con 3 coberturas para su contratación: Amplia, Limitada y
              Daños a Terceros.
            </p>

            <h2>Cobertura amplia</h2>
            <p>
              Tu auto estará protegido ante choques, robo, rotura de cristales,
              apoyo con servicios de emergencia como: Grúa, Cerrajero, Cambio de
              llanta, Gasolina y paso de corriente.
            </p>

            <h2>Cobertura limitada</h2>
            <p>
              Tu auto estará protegido contra robo y con apoyo de servicios de
              emergencia como: Grúa, Cerrajero, Cambio de llanta, Gasolina y
              paso de corriente.
            </p>
          </div>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0">
          
          <dot-pattern-right />
          
          <!-- <img class="relative mx-auto" width="490" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-anchor-placement="top-center" src="@/assets/images/ilustraciones/ilustraciones_cotiza.svg" alt="" /> -->
        </div>
      </div>
      <dot-pattern-left />
      <civil-responsability />
    </div>
  </div>
</template>
<script>
import CivilResponsability from "@/components/cars/CivilResponsability.vue";
import DotPatternLeft from "@/components/common/DotPatternLeft.vue";
import DotPatternRight from "@/components/common/DotPatternRight.vue";
export default {
  components: {
    CivilResponsability,
    DotPatternRight,
    DotPatternLeft,
  },
  setup() {},
};
</script>
