<template lang="en">
<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
  <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
  </loading>
  <div class="mx-auto w-full max-w-sm lg:w-96">
    <div>
      <router-link to="/">
        <img
          class="w-20"
          src="@/assets/images/logo.png"
          alt="Workflow"
      /></router-link>
      <h2 class="mt-6 text-3xl leading-9 font-extrabold text-orange-500">
        Recuperar cuenta
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w">Para recuperar tu cuenta ingresa la dirección de tu correo electrónico. Te enviaremos un mensaje con las indicaciones para restablecer el acceso.</p>
    </div>
    <div class="mt-8">
      <div>
        <div class="mt-6">
          <form @submit="recoverAccount($event)" id="form_recover" class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Correo electrónico</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input id="input_email" type="email" v-model="email" required minlength="10" maxlength="50" pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
            </div>
            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button type="submit" id="btn_send" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                  Enviarme instrucciones
                </button>
              </span>
            </div>
          </form>
          <br><br><router-link to="/login">
            <button id="btn_regresar" class="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
              Volver
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import User from "@/classes/User.js"
import {
  ref
} from 'vue';
import Swal from 'sweetalert2';
import {
  useRouter
} from 'vue-router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "AccountRecover",
  components: {
    Loading
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const loader = ref('spinner');
    const email = ref("");

    async function recoverAccount(e) {
      e.preventDefault();
      isLoading.value = true;
      User.recover(email.value)
        .then((response) => {
          console.log("response", response);
          Swal.fire({
            title: "Aviso",
            text: "Para restablecer su contraseña se le enviará las instrucciones al correo proporcionado, si no lo encuentra favor de buscar en correo no deseado.",
            icon: "success",
          }).then(function () {
            router.push({
              name: "Login"
            });
          });
        })
        .catch((error) => {
          console.log("Se presentó un error: ", error);
          let messageError = error.response.data.errores;
          Swal.fire({
            title: "Aviso",
            html: `<span style="font-weight: bold; color:red">${messageError == undefined ? "No fue posible enviar el correo" : messageError}</span>`,
            icon: "error",
          });

        }).finally(() => {
          isLoading.value = false;
        });
    }

    return {
      email,
      isLoading,
      loader,
      recoverAccount
    };
  }
}
</script>
