<template>
    <div style="min-height: 640px">
        <div class="h-screen flex overflow-hidden bg-white"
        x-data="{ sidebarOpen: false }"
        keydown.window.escape="sidebarOpen = false">
        <dashboard-side-bar-menu />
            <div x-init="$el.focus()"
                class="flex-1 overflow-auto focus:outline-none"
                tabindex="0">
            <dashboard-side-bar-menu-mobile />
            <loading :active="isLoading" 
                                :can-cancel="false" 
                                :color="'#bfd243'" 
                                :height="100" 
                                :width="350" 
                                :loader="loader"/>
                <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
                    <dashboard-header />
                    <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" style="min-width: 100%;">
                        <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4">
                            
                            <div class="tabPanel-widget" style>
                                <label for="tab-1" tabindex="0"></label>
                                <input id="tab-1" type="radio" name="tabs" checked="true" aria-hidden="true"
                                    value="0"
                                    @click="selectTab($event)">
                                <h2>COTIZACION</h2>
                                <div>
                                    <ErrorHistoryTable ref="tableRefCotizacion" 
                                        :arrayFields="dataFields" 
                                        :rows="rows[0]" 
                                        :columns="columns" 
                                        :tableId="tableId[0]"
                                        :dateRanges="dateRanges"
                                        :isOpen="isOpen[0]"/>
                                </div>
                                <label for="tab-2" tabindex="0"></label>
                                <input id="tab-2" type="radio" name="tabs" aria-hidden="true"
                                    value="1"
                                    @click="selectTab($event)">
                                <h2>EMISION</h2>
                                <div>
                                    <ErrorHistoryTable ref="tableRefEmision" 
                                        :arrayFields="dataFields" 
                                        :rows="rows[1]" 
                                        :columns="columns" 
                                        :tableId="tableId[1]"
                                        :dateRanges="dateRanges"
                                        :isOpen="isOpen[1]"/>
                                </div>
                                <label for="tab-3" tabindex="0"></label>
                                <input id="tab-3" type="radio" name="tabs" aria-hidden="true"
                                    value="2"
                                    @click="selectTab($event)">
                                <h2>PAGOS</h2>
                                <div>
                                    <ErrorHistoryTable ref="tableRefPago" 
                                        :arrayFields="dataFields" 
                                        :rows="rows[2]" 
                                        :columns="columns" 
                                        :tableId="tableId[2]"
                                        :dateRanges="dateRanges"
                                        :isOpen="isOpen[2]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue' 

import Loading from 'vue-loading-overlay';
import DashboardSideBarMenu from "@/components/dashboard/DashboardSideBarMenu";
import DashboardSideBarMenuMobile from "@/components/dashboard/DashboardSideBarMenuMobile";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import ErrorHistoryTable from '@/components/dashboard/error/ErrorHistoryTable.vue';

import { getUserAuthInfo } from "@/helpers/auth.js";
import { getDayFirstAndLastDayOfYear, 
        getMonthFirstAndLastDayOfMonth,
        getDayFirstAndLastDayOfWeek,
        subtractDate,
        dateFormatForUserView } from '@/helpers/date.js';

import '@/assets/css/tabpanel.css'

export default{
    components: {
        DashboardSideBarMenu,
        DashboardSideBarMenuMobile,
        DashboardHeader,
        ErrorHistoryTable,
        Loading
    },
    setup() {
    const tableRefCotizacion = ref();
    const tableRefEmision = ref();
    const tableRefPago = ref();
    let dataFields = ref();
    let columns = ref();
    let rows = ref([[]]);
    let tableId = ref(["data-table-cotizacion","data-table-emision","data-table-pago"]);
    let dateRanges = ref([]);
    let isOpen = ref([]);
    const isLoading = ref(false);
    const multicrapi = process.env.VUE_APP_MULTICR;
    let config = ref({
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getUserAuthInfo().token}`
        }
    });
    onMounted(async () => {
        isLoading.value = true;
        getReport();
        isLoading.value = false;
    });

    async function getReport() {
        columns.value = ["Folio","Descripcion","Fecha","Origen","Cliente"];
        dataFields.value = ["id","respuesta","created_at","nombre","nombre_cliente"];
        var first_date_params = new Date();
        var params = getDayFirstAndLastDayOfYear(first_date_params);
        if(first_date_params.getMonth() == 0 ){
            params.first= subtractDate(first_date_params,'month',1);
        }

        await axios.get(multicrapi + '/administrador/obtener_errores?first_date=' + params.first + '&last_date=' + params.last,config.value).then((response) => {
            
            rows.value[0] = response.data.respuesta.errores_cotizacion.map(item => ({
                ...item,
                respuesta: JSON.stringify(item.respuesta).match(/.{1,100}/g)[0],
                respuesta_ext: JSON.stringify(item.respuesta).replace(/(.{90})/g, "$1<br>"),
                show_ext: false,
                created_at: "<div style='display:none;'>" + item.created_at + "</div>" + 
                    dateFormatForUserView(item.created_at)
            }));
            rows.value[1] = response.data.respuesta.errores_emision.map(item => ({
                ...item,
                respuesta: JSON.stringify(item.respuesta).match(/.{1,100}/g)[0],
                respuesta_ext: JSON.stringify(item.respuesta).replace(/(.{90})/g, "$1<br>"),
                show_ext: false,
                created_at: "<div style='display:none;'>" + item.created_at + "</div>" + 
                    dateFormatForUserView(item.created_at)
            }));
            rows.value[2] = response.data.respuesta.errores_pago.map(item => ({
                ...item,
                respuesta: JSON.stringify(item.respuesta).match(/.{1,100}/g)[0],
                respuesta_ext: JSON.stringify(item.respuesta).replace(/(.{90})/g, "$1<br>"),
                show_ext: false,
                created_at: "<div style='display:none;'>" + item.created_at + "</div>" + 
                    dateFormatForUserView(item.created_at)
            }));
        });

        //generate date ranges
        let weekly = getDayFirstAndLastDayOfWeek(first_date_params);
        let monthly = getMonthFirstAndLastDayOfMonth(first_date_params);
        let anually = getDayFirstAndLastDayOfYear(first_date_params);
        let lastWeek = getDayFirstAndLastDayOfWeek(subtractDate(first_date_params,'day',7));
        let lastMonth = getMonthFirstAndLastDayOfMonth(subtractDate(first_date_params,'month',1));
        dateRanges.value = [
            [weekly.first, weekly.last],
            [monthly.first, monthly.last],
            [anually.first, anually.last],
            [lastWeek.first, lastWeek.last],
            [lastMonth.first, lastMonth.last]
            ];
        isOpen.value = [true,false,false];
        
        tableRefCotizacion.value.createTable();
        tableRefEmision.value.createTable();
        tableRefPago.value.createTable();
    }
    
    function selectTab(event){
    isOpen.value = [false, false, false]    
    isOpen.value[event.target.value] = true;
    }

    return {
        isLoading,
        tableRefCotizacion,
        tableRefEmision,
        tableRefPago,
        tableId,
        dateRanges,
        dataFields,
        columns,
        rows,
        isOpen,
        selectTab
    }
    },
};
</script>