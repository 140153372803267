<template>
  <h3>
    VI. SOLICITUD DE EJERCICIO DE DERECHO ARCO
  </h3>
  <p>
    Cabsa SEGUROS, AGENTE DE SEGUROS Y DE FIANZAS S.A DE C.V.
    con domicilio en Flavio Bórquez 1613, Col Prados del tepeyac, C.P. 85150,
    en Ciudad Obregón, Sonora; hace de su conocimiento que de conformidad con lo
    establecido en el Capítulo IV de la Ley Federal de Protección de Datos
    Personales en Posesión de los Particulares, usted tiene derecho ejercer en
    cualquier momento sus derechos de acceso, rectificación, cancelación y
    oposición de sus datos personales directamente con la empresa. (Derechos
    ARCO).
  </p>
  <p>
    De igual manera le informamos que para poder ejercer cualquiera de sus
    Derechos ARCO, es indispensable que la persona solicitante sea el Titular de
    los Datos, por lo que deberá ser acreditada la identidad con una
    identificación oficial vigente y en caso de ser representante legal, con un
    documento acreditativo de la representación del interesado, adicional a una
    identificación oficial vigente. A continuación se enlista cada uno de los
    Derechos ARCO con su definición:
  </p>
  <ul>
    <li>
      Derecho de Acceso: Derecho del Titular a solicitar a la Empresa que le sea
      informado si en sus bases de datos cuenta coninformación alguna de sus
      datos personales.
    </li>
    <li>
      Derecho de Rectificación: Derecho del Titular a solicitar a la Empresa que
      corrija los datos personales que posee en sus bases, cuando estos sean
      incorrectos, imprecisos, incompletos o estén desactualizados. (Deberá
      exhibir y proporcionar copia simple del documento).
    </li>
    <li>
      Derecho de Cancelación: Derecho del Titular de solicitar a la Empresa la
      cancelación de sus datos personales que posea en sus bases. (Deberá
      especificar los datos que se requiere que sean cancelados). Este derecho
      quedara sujeto a lo establecido en el Artículo 140 de la Ley General de
      Instituciones y Sociedades Mutualistas de Seguros.
    </li>
    <li>
      Derecho de Oposición: Derecho del Titular para solicitar a la Empresa que
      es poseedora de sus datos personales en sus bases que se abstenga del
      tratamiento de sus datos en determinadas situaciones. (Deberá especificar
      las razones por las cuales seopone al tratamiento).
    </li>
  </ul>
</template>
