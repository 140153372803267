<template>
  <div class="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" v-show="openNavBar">
    <div class="rounded-lg shadow-md">
      <div class="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
        <div class="px-5 pt-4 flex items-center justify-between">
          <div>
            <img class="h-4 w-auto" src="@/assets/images/logo.png"/>
          </div>
          <div class="-mr-2">
            <button type="button" @click="closeMenu" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="px-2 pt-2 pb-3">
          <a href="/#mision-vision" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" style="text-align: center;">Misión-Visión</a>
          <a href="/#nosotros" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" style="text-align: center;" >Nosotros</a>
          <a href="/contactanos" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" style="text-align: center;">Contacto</a>
          <a target="_blank" href="https://Cabsaseguros.com/blog/preguntas-frecuentes/" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" style="text-align: center;">FAQ</a>
          <a href="/#nuestros-productos" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem" style="text-align: center;">Nuestros Productos</a>
        </div>
        <div class="md:flex md:items-center md:justify-end md:inset-y-0">
          <span v-if="_loginDataUser.status==false">
            <a href="/login" class="block px-3 py-2 rounded-md text-base font-medium text-orange-700 hover:text-orange-900 hover:bg-orange-50 focus:outline-none focus:text-orange-900 focus:bg-orange-50 transition duration-150 ease-in-out" style="text-align: center;">Iniciar Sesión</a>
          </span>
          <span v-if="_loginDataUser.status==true && _loginDataUser.rol != 'Cliente'">
            <a href="panel-administrativo" class="block px-3 py-2 rounded-md text-base font-medium text-orange-700 hover:text-orange-900 hover:bg-orange-50 focus:outline-none focus:text-orange-900 focus:bg-orange-50 transition duration-150 ease-in-out" style="text-align: center;">{{_loginDataUser.nombre}}</a>
            <a @click="logOut()" class="block px-3 py-2 rounded-md text-base font-medium text-orange-700 hover:text-orange-900 hover:bg-orange-50 focus:outline-none focus:text-orange-900 focus:bg-orange-50 transition duration-150 ease-in-out" style="text-align: center;">Cerrar Sesión</a>
          </span>
          <span v-if="_loginDataUser.status==true && _loginDataUser.rol == 'Cliente'">
            <a href="/cliente/inicio" class="block px-3 py-2 rounded-md text-base font-medium text-orange-700 hover:text-orange-900 hover:bg-orange-50 focus:outline-none focus:text-orange-900 focus:bg-orange-50 transition duration-150 ease-in-out" style="text-align: center;">{{_loginDataUser.nombre}}</a>
            <a @click="logOut()" class="block px-3 py-2 rounded-md text-base font-medium text-orange-700 hover:text-orange-900 hover:bg-orange-50 focus:outline-none focus:text-orange-900 focus:bg-orange-50 transition duration-150 ease-in-out" style="text-align: center;">Cerrar Sesión</a>
          </span>
          <span class="inline-flex rounded-md shadow">
            
          </span>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from '@vue/runtime-core';
import { onMounted, ref } from '@vue/runtime-core'
import {getUserAuthInfo,deleteUserAuth} from "@/helpers/auth.js";

export default {
  
  setup() {
    const openNavBar = inject('openNavBar');
    function closeMenu(){
      openNavBar.value = false;
    }

    let _loginDataUser=ref({
      status:false,
      nombre:"",
      rol:""
    }
    );

    onMounted(()=>{
      let res=getUserAuthInfo()
      if(res){
        _loginDataUser.value.nombre=`${res.usuario_actual}`;
        _loginDataUser.value.status=true;
        _loginDataUser.value.nombre=`${res.rol}`;
      }
      else{
        _loginDataUser.value.nombre="";
        _loginDataUser.value.status=false;
        _loginDataUser.value.nombre="";
      }
    })

    function logOut(){
      _loginDataUser.value.nombre="";
      _loginDataUser.value.status=false;
      _loginDataUser.value.nombre="";
      deleteUserAuth();

    }


    return{
      _loginDataUser,
      logOut,
      closeMenu,
      openNavBar
    }
  },
};
</script>
