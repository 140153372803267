
<template>
  <div class="py-16 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div id="trigger-second-services" class="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="text-base max-w-prose mx-auto">
          <p class="leading-6 text-orange-500 font-semibold tracking-wide uppercase">
            Protegerte es lo más importante 
          </p>
          <h1 class="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Expertos en Seguros
          </h1>
          <div class="prose text-gray-500 text-lg">
            <h2> Beneficios de contratar un seguro de flotilla con Cabsa   </h2>
            <ul>
              <li>Seguro a la medida de tu negocio </li>
              <li>La mejor atención y asesoría personalizada </li>
              <li>Asistencia durante siniestro</li>
              <li>Varias opciones para contratar  </li>
            </ul>

            <h2>¿Este tipo de seguro cubre si dos unidades de la misma flotilla colisionan entre sí? </h2>
            <p>
             La cobertura RC cubre los daños que puedes ocasionar a terceros, es por ello que, si el 
             percance es entre vehículos de la misma flotilla, no cubrirá los gastos. 
            </p>
              <p>
              Es recomendable se agregue a su póliza la cobertura <b>RC Cruzada</b>, la cual está diseñada 
              para cubrir los daños ocasionados entre flotillas. 
            </p>

            <h2>¿Solo las empresas pueden contratar un seguro de flotilla? </h2>
            <p>
              Es común que negocios aseguren su parque vehicular para que estos cumplan con sus
              objetivos de forma segura, pero también grupos y asociaciones pueden contrata
              un seguro de flotillas para conseguir mejores condiciones al tratarse de más de 2 
              vehículos. 
            </p>
            <p>
              Una familia o incluso un grupo de amigos se pueden beneficiar con un seguro de
              flotilla y conseguir mejores condiciones. 
            </p>

            <h2> Este tipo de seguros puede ser contratado por:  </h2>
            <ul>
              <li>Empresas </li>
              <li>Asociaciones </li>
              <li>Grupos o colectivos de personas particulares </li>
            </ul>
          </div>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0">
          
          <dot-pattern-right />
          <!-- <img class="relative mx-auto" height="400" width="600" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-anchor-placement="top-center" src="@/assets/images/ilustraciones/img-empresas-anter_empresas-2.svg" alt="" /> -->
        </div>
      </div>
      <dot-pattern-left />
      <cars-business-civil-responsability />
    </div>
  </div>
</template>
<script>
import CarsBusinessCivilResponsability from "@/components/carsBusiness/CarsBusinessCivilResponsability.vue";
import DotPatternLeft from "@/components/common/DotPatternLeft.vue";
import DotPatternRight from "@/components/common/DotPatternRight.vue";
export default {
  components: {
    CarsBusinessCivilResponsability,
    DotPatternRight,
    DotPatternLeft,
  },
  setup() {},
};
</script>
