<template>
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24" id="products">
    <div class="mx-auto max-w-7xl w-full text-center lg:text-left">
      <div class="px-4 sm:px-8" id="nuestros-productos">
        <p class="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-3xl lg:text-3xl xl:text-3xl">
          <span class="text-3xl tracking-tight leading-10 font-extrabold sm:text-3xl sm:leading-none md:text-3xl lg:text-3xl xl:text-3xl text-orange-500">Nuestros</span> productos
        </p>
        <p class="mt-3 mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 xl:pr-64">
          En <b>Cabsa</b> nos hemos enfocado en la protección de nuestros clientes, por ello además de ofrecer un <b>Seguro de Autos</b>, hemos sumado una suscripción médica para proteger uno de los aspectos más importantes de su vida, la salud.
        </p>
      </div>
    </div>
    <div class=" bg-white">
      <div class="pt-5 max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
          <cars-service />
          <health-service />
          <cars-business-service/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CarsService from "@/components/main-page/our-services/CarsService";
import HealthService from "@/components/main-page/our-services/HealthService";
import CarsBusinessService from "@/components/main-page/our-services/CarsBusinessService";

export default {
  components: {
    CarsService,
    HealthService,
    CarsBusinessService
  },
  setup() {},
};
</script>
