<template>
  <div class="relative mt-12 sm:mt-16 lg:mt-24" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-center" data-aos-anchor="#nosotros">
    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="lg:col-start-2">
        <div class="text-base max-w-prose mx-auto">
          <div class="prose text-gray-500 text-lg">
            
            <h2> ¿Qué cubre?  </h2>
            <ul>
              <li>Daños Materiales</li>
              <li>Robo total</li>
              <li>Gastos médicos a ocupantes </li>
              <li>RC por daños a terceros</li>
              <li>Defensa legal </li>
              <li>Coberturas especiales</li>
              
            </ul>
            <h6 class="prose text-gray-500 text-lg">*Un experto de Cabsa te podrá brindar asesoría de las coberturas especiales </h6>
            <h2> ¿Qué información se necesita para cotizar una flotilla? </h2>
            
            <ol>
              <li>Información general del negocio (Domicilio, giro, actividad)</li>
              <li>RFC</li>
              <li>Descripción completa de cada vehículo a asegurar (Año, serie, placas, origen, cobertura que se desea contratar) </li>
              <li>Uso de las unidades</li>
              <li>Si cuentan con alguna adaptación o equipo especial </li>
              <li>Tipo de carga (cuando aplique)</li>
            </ol>
            
          </div>
        
          
          
        
        </div>
      </div>

      <!-- <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
        <img class="relative mx-auto" width="490" data-aos="fade-right" data-aos-duration="1500"
          data-aos-delay="100" data-aos-anchor-placement="top-center"
          data-aos-anchor="#nosotros"
          src="@/assets/images/ilustraciones/img-empresas-anter.svg"
          alt="">
      </div> -->
    </div>
  </div>
</template>