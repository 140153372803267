<template>
  <div class="relative overflow-hidden">
    <div x-data="{ open: true }" class="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32" >
      <header-component />
      <main-quoter-component />
    </div>
  </div>
</template>
<script>
import HeaderComponent from "@/components/quoter/HeaderComponent.vue";
import MainQuoterComponent from "@/components/quoter/MainQuoterComponent.vue";


export default {
  name: 'Quoter',
  components: {
    HeaderComponent,
    MainQuoterComponent
  
  }
};
</script>
