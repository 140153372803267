<template>
  <div id="mision-vision" class="relative mt-12 sm:mt-16 lg:mt-24" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-center" data-aos-anchor="#trigger-second-services">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Misión
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-500">
              Buscamos brindarles a nuestros clientes la mejor experiencia de 
              compra a través del uso de tecnología financiera.
            </p>
            <h4 class="mt-10 text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9" >
              Visión
            </h4>
            <p class="mt-3 text-lg leading-7 text-gray-500">
              Ser la empresa insurtech con mayor aportación en el mercado 
              para sus clientes y colaboradores, utilizando tecnología 
              financiera innovadora.
            </p>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
            </svg>
            <!-- <img class="relative mx-auto" width="490" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="100" data-aos-anchor-placement="top-center" data-aos-anchor="#trigger-second-services" src="@/assets/images/ilustraciones/ilustraciones_nosotros.svg" alt=""> -->
          </div>
        </div>
      </div>
</template>