<template lang="en">
<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
  <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
  </loading>
  <div class="mx-auto w-full max-w-sm lg:w-96">
    <div>
      <img class="w-20" src="@/assets/images/logo.png" alt="Workflow" />
      <h2 class="mt-6 text-3xl leading-9 font-extrabold text-orange-500">
        Confirmar cuenta
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w">Para garantizar la seguridad de tu información enviaremos al correo electrónico registrado un enlance de confirmación de cuenta. Una vez confirmado serás parte de Cabsa.</p>
    </div>
    <div class="mt-8">
      <div>
        <div class="mt-6">
          <div  class="space-y-6">
            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button type="button" id="btn_confirm_account" v-on:click="confirmAccount($event)" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                  Entendido
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref
} from 'vue';
import {
  useRouter
} from 'vue-router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "ConfirmAccount",
  components: {
    Loading
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const loader = ref('spinner');

    async function confirmAccount(e) {
      e.preventDefault();
      router.push({
        name: "Login"
      });
    }

    return {
      isLoading,
      loader,
      confirmAccount
    };
  }
}
</script>
