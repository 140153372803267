<template>
    <div style="min-height: 640px">
        <div class="h-screen flex overflow-hidden bg-white"
        x-data="{ sidebarOpen: false }"
        keydown.window.escape="sidebarOpen = false">
        <dashboard-side-bar-menu />
            <div x-init="$el.focus()"
                class="flex-1 overflow-auto focus:outline-none"
                tabindex="0">
            <dashboard-side-bar-menu-mobile />
            <loading :active="isLoading" 
                                :can-cancel="false" 
                                :color="'#bfd243'" 
                                :height="100" 
                                :width="350" 
                                :loader="loader"/>
                <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
                    <dashboard-header />
                    <div class="mt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" style="min-width: 100%;">
                        <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col justify-betweeen col-span-3 p-4">
                            <div class="md:float-right pb-4" data-v-2a6694eb="">
                                <select
                                    style="width: 200px"
                                    @change="filterDate($event)"
                                >
                                    <option>Semanal</option>
                                    <option>Mensual </option>
                                    <option selected="selected">Anual</option>
                                    <option>Semana pasada</option>
                                    <option>Mes pasado</option>
                                </select> 
                                <button class="w-full md:w-auto lg:w-2/2 xl:w-2/2 sm:mx-auto 
                                md:mx-auto lg:mx-2 xl:mx-2 my-1 shadow bg-orange-400 hover:bg-orange-600 
                                focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded 
                                inline-flex items-center" 
                                style="float: right;"
                                @click="tableRef.exportExcel(initialDate,finalDate)" 
                                type="button"> 
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                        class="h-6 w-6 mr-1" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor">
                                    <path stroke-linecap="round" 
                                        stroke-linejoin="round" 
                                        stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                                    </svg>
                                    Exportar
                                </button>
                            </div>
                            <div>
                                <center>Reporte de
                                    {{ dateFormatForUserView(initialDate) }} al 
                                    {{ dateFormatForUserView(finalDate) }}
                                </center>
                                <Table ref="tableRef"
                                :arrayFields="dataFields" 
                                :rows="rows" 
                                :columns="columns" 
                                :reportType="reportType"
                                dataLength="true"/>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

import Loading from 'vue-loading-overlay';
import DashboardSideBarMenu from '@/components/dashboard/DashboardSideBarMenu';
import DashboardSideBarMenuMobile from '@/components/dashboard/DashboardSideBarMenuMobile';
import DashboardHeader from '@/components/dashboard/DashboardHeader';

import Table from '@/components/dashboard/reports/ReportTable.vue';
import { getUserAuthInfo } from "@/helpers/auth.js";
import { getDayFirstAndLastDayOfYear, 
        getMonthFirstAndLastDayOfMonth,
        getDayFirstAndLastDayOfWeek,
        dateFormatForUserView,
        subtractDate } from '@/helpers/date.js';
export default{
    components:{
        DashboardSideBarMenu,
        DashboardSideBarMenuMobile,
        DashboardHeader,
        Table,
        Loading,
    },
    setup(){
        const tableRef = ref();
        const isLoading = ref(false);
        let dataFields = ref();
        let columns = ref();
        let rows = ref([]);
        let reportType = ref("ReporteVentas");
        // const dateRanges = ref([]);
        const initialDate = ref();
        const finalDate = ref();
        const multicrapi = process.env.VUE_APP_MULTICR;
        let config = ref({
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserAuthInfo().token}`
          }
        });
        onMounted(async () => {
            isLoading.value = true;
            getReport();
            isLoading.value = false;
        });

        async function getReport() {
            columns.value = ["Folio", "Nombre", "Rol", "Cotizaciones", "Ventas"];
            dataFields.value = ["usuario_id", "nombre_usuario","rol", "numero_cotizaciones", "numero_ventas"];
            var date = new Date();
            let anually = getDayFirstAndLastDayOfYear(date);
            if(date.getMonth() == 0 ){
                anually.first= subtractDate(date,'month',1);
            }
            
            await axios.get(multicrapi + '/administrador/obtener_reporte_de_ventas?first_date=' + anually.first + '&last_date=' + anually.last,config.value).then((response) => {
                rows.value = response.data.respuesta;
            });
            tableRef.value.createTableSortColumnIndex(4);
            
            initialDate.value = new Date(anually.first);
            finalDate.value = new Date(anually.last);

        }

        async function filterDate(event){
					let resultDate;
					switch(event.target.value){
						case 'Semanal': resultDate = getDayFirstAndLastDayOfWeek(new Date()); break;
						case 'Mensual': resultDate = getMonthFirstAndLastDayOfMonth(new Date()); break;
						case 'Anual': resultDate = getDayFirstAndLastDayOfYear(new Date()); break;
						case 'Semana pasada': resultDate = getDayFirstAndLastDayOfWeek(subtractDate(new Date(),'day',7)); break;
						case 'Mes pasado': resultDate = getMonthFirstAndLastDayOfMonth(subtractDate(new Date(),'month',1)); break;
					}
					initialDate.value = resultDate.first;
					finalDate.value = resultDate.last;
					
					isLoading.value = true;
					await axios.get(multicrapi + '/administrador/obtener_reporte_de_ventas?first_date=' + initialDate.value + '&last_date=' + finalDate.value,config.value).then((response) => {
						rows.value = response.data.respuesta;
					});
					tableRef.value.createTableSortColumnIndex(4);
					isLoading.value = false;
        }

        return {
            tableRef,
            isLoading,
            dataFields,
            columns,
            rows,
            reportType,
            filterDate,
            initialDate,
            finalDate,
            dateFormatForUserView
        };
    }
}
</script>