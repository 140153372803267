<template>
<main class="lg:relative text-gray-700 relative px-2 md:px-0 mt-2">
  <div class="vld-parent">
    <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
    </loading>
  </div>
  <quoter-nav v-if="route.name == 'Questions'" />
  <div class="flex justify-center items-center">
    <div class="max-w-xl w-full">
      <virtual-assistant v-if="route.name == 'Quoter'" /> <!-- v-if = "step == 1"-->
      <providers v-if="route.name == 'Quoter'" />
      <products v-if="route.name == 'Products'" />
      <!--v-if = "step == 2"-->

      <steps v-if="route.name == 'Questions'"></steps>
      <questionsDependents v-if="route.name == 'QuestionsDependents'"></questionsDependents>
      <!--v-if = "step == 3 || step == 6"-->

      <quote-resume v-if="route.name == 'QuoteResume'" />
      <!--v-if = "step == 4"-->
      <issuance-resume v-if="route.name == 'IssuanceResume'" />

      <life-issuance-resume v-if="route.name == 'LifeIssuanceResume'" />

      <!--v-if = "step == 7"-->
      <way-of-payment v-if="route.name == 'WayOfPayment'" />
      <!--v-if = "step == 8"-->
    </div>
  </div>
  <payment-completed v-if="route.name == 'PaymentCompleted'" />
  <life-payment-completed v-if="route.name == 'LifePaymentCompleted'" />
  <!--v-if = "step == 9"-->
  <quotation v-if="route.name == 'Quotation'" />
  <!--v-if = "step == 5"-->

</main>
</template>

<script>
import {
  useRouter,
  useRoute
} from 'vue-router';
import Providers from "@/components/common/Providers.vue";
import QuoterNav from "@/components/quoter/QuoterNav.vue";
import Quotation from "@/components/quoter/Quotation.vue";
import VirtualAssistant from "@/components/quoter/VirtualAssistant.vue";
import Products from "@/components/quoter/Products.vue";
import Steps from "@/components/quoter/Steps.vue";
import QuoteResume from "@/components/quoter/QuoteResume.vue";
import PaymentCompleted from "@/components/quoter/PaymentCompleted.vue";
import LifePaymentCompleted from "@/components/quoter/LifePaymentCompleted.vue";
import LifeIssuanceResume from "@/components/quoter/LifeIssuanceResume.vue";
import IssuanceResume from "@/components/issuance/IssuanceResume.vue";
import QuestionsDependents from "@/components/quoter/QuestionsDependents.vue";

import {
  provide,
  ref,
  onMounted
} from "vue";
import WayOfPayment from "@/components/quoter/WayOfPayment.vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "MainQuoterComponent",
  components: {
    Providers,
    QuoterNav,
    VirtualAssistant,
    Products,
    Steps,
    Loading,
    QuoteResume,
    Quotation,
    PaymentCompleted,
    IssuanceResume,
    WayOfPayment,
    QuestionsDependents,
    LifeIssuanceResume,
    LifePaymentCompleted
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const contSteps = ref(0);
    const contCategory = ref(0);
    const step = ref(1);
    const help = ref(false);
    const aspects = ref([]);
    const products = ref([]);
    const categories = ref([]);
    
    
    const car_description = ref({
      type: "",
      year: "",
      brand: "",
      sub_brand: "",
      description: ""
    });

    const selected_products = ref();
    const issuanceParams = ref({
      "product": "Auto",
      "provider": "gnp",
      "body_json": {
        car: {
          origin: "Residente",
          type: "",
          year: "",
          brand: "",
          brand_value: "",
          sub_brand: "",
          sub_brand_value: "",
          description: "",
          description_value: "",
          code: {},
          list_codes: {},
          vin: "",
          motor: "SN",
          enrolment: ""
        },
        client: {
          birth: "",
          gender: "",
          names: "",
          paternal_last_name: "",
          maternal_last_name: "",

          email: "",
          phone: "",
          rfc: "",
          nacionality: "MEX",
          marital_status: "C"
        },
        address: {
          postal_code: "",
          street: "",
          outdoor_number: "",
          interior_number: "",
          state_code: "",
          state: "",
          city_code: "",
          city: "",
          town_code: "",
          town: "",
          neighborhood_code: "",
          neighborhood: ""
        },
        quote: {
          issuance_result: "",
          quote_provider_id: "",
          coverage: "",
          payment_frequency: "",
          quote_number: "",
          total_amount: "",
          first_amount: "",
          net_amount: "",
          tax: "",
          rpf: "",
          derecho: ""
        }
      }
    });

    const issuanceRegistered = ref({
      issuance_id: "",
      link_pdf: "",
      json: ""
    });

    const carInfo = ref({
      type: "",
      year: "",
      brand: "",
      sub_brand: "",
      version: ""
    });
    const questionsPersonalInfo = ref([])
    const answer = ref("");
    const answers = ref([]);
    const questionId = ref(null);
    const isLoading = ref(false);

    
    const arrayParamsGlobal = ref([]);
    const arrayAnswers = ref([]);
    const clientName = ref("");
    const quoteId = ref("");
    const issuanceId = ref("");
    const policyNumber = ref("");
    const responseQuoteRegister = ref({});
    
    const activeProviders = ref([]);
    const currentCategoryName = ref("");
    const arrayIdQuestions = ref([]);
    const showQuestion = ref(true);
    const selectedProducts = ref([]);
    const issuedProducts = ref([]);
    const jsonQuotationResumen = ref({});
    const responseQuoteInfo = ref([]);
    
    const dependentData = ref([]);
    const dependentClinicHistory = ref([]);
    const dependentObstetricHistory = ref([]);
    const arrayDependents = ref([]);
    const arrayAnswersDependents = ref([]);
    const currentSubcategoryName = ref("");
    const titularGender = ref("");
    const currentProductName = ref("");
    const arrayCategories = ref([]);
    const arrayCategoriesDep = ref([]);
    const lifeIssuanceResponseParams = ref([]);
    const loader = ref('spinner');
    const validationError = ref(true);
    const showMessage = ref(false);
    const message = ref("");
    const stepName = ref("");
    const buttonDisabled = ref(true);

    const selectedProviderQuote = ref("");
    const issuanceResponseParams = ref();
    //REFACTORIZAR NOMBRES
    const parametrosCotizacion = ref({
      auto: {
        cobertura: "Amplia",
        frecuencia_de_pago: "Anual",
        tipo: "",
        modelo: "",
        marca: "",
        marca_id: "",
        submarca: "",
        submarca_id: "",
        descripcion: "",
        descripcion_id: "",
        lista_de_claves_de_auto: {}
      },
      cliente: {
        fecha_nacimiento: "",
        sexo: "",
        nombres: "",
        apellido_paterno: "",
        apellido_materno: "",
        email: "",
        telefono: "",
        codigo_postal: "",
      },
      prospecto_id: "",
      cotizacion_id: "",
      usuario_id: "",
      respuesta_id: ""
    });
    const parametrosEmision = ref({
      proveedor: "",
      informacion:{
        auto: {
          cobertura: "Amplia",
          frecuencia_de_pago: "Anual",
          tipo: "",
          modelo: "",
          marca: "",
          marca_id: "",
          submarca: "",
          submarca_id: "",
          descripcion: "",
          descripcion_id: "",
          lista_de_claves_de_auto: {},
          placas: "",
          numero_serie: ""
        },
        cliente: {
          fecha_nacimiento: "",
          sexo: "",
          nombres: "",
          apellido_paterno: "",
          apellido_materno: "",
          email: "",
          telefono: "",
          codigo_postal: "",
          rfc: "",
          curp: "",
          colonia: "",
          calle: "",
          numero_exterior: "",
          numero_interior: ""
        },
        cotizacion: {
          numero_cotizacion: "",
          importe_total: "",
          primer_importe: "",
          importe_subsecuente: "",
          prima_neta: "",
          impuesto: "",
          control_id: ""
        },
        cotizacion_proveedor_id: "",
        prospecto_id: "",
        cotizacion_id: "",
        usuario_id: "",
        respuesta_id: ""
      }
    });
    const parametrosPago = ref({
      proveedor: "",
      informacion:{
        auto: {
          cobertura: "Amplia",
          frecuencia_de_pago: "Anual",
          tipo: "",
          modelo: "",
          marca: "",
          marca_id: "",
          submarca: "",
          submarca_id: "",
          descripcion: "",
          descripcion_id: "",
          lista_de_claves_de_auto: {},
          placas: "",
          numero_serie: ""
        },
        cliente: {
          fecha_nacimiento: "",
          sexo: "",
          nombres: "",
          apellido_paterno: "",
          apellido_materno: "",
          email: "",
          telefono: "",
          codigo_postal: "",
          rfc: "",
          curp: "",
          colonia: "",
          calle: "",
          numero_exterior: "",
          numero_interior: ""
        },
        cotizacion: {
          numero_cotizacion: "",
          importe_total: "",
          primer_importe: "",
          importe_subsecuente: "",
          prima_neta: "",
          impuesto: "",
          control_id: ""
        },
        emision: {
          numero_poliza: "",
          numero_endoso: "",
          facturas: "",
          primer_importe: "",
          importe_subsecuente: "",
          importe_total: "",
          prima_neta: "",
          impuesto: ""
        },
        tarjeta: {
          nombre_titular: "",
          numero: "",
          mes_expiracion: "",
          año_expiracion: "",
          cvv: "",
          tipo: "",
          banco: ""
        },
        emision_id: "",
        cotizacion_proveedor_id: "",
        prospecto_id: "",
        cotizacion_id: "",
        usuario_id: "",
        respuesta_id: ""
      }
    });
    const paymentResponse = ref({});
    
    const parametrosSalesforce = ref({
        "cliente":{
          "nombres": "", 
          "apellido_paterno": "", 
          "apellido_materno": "", 
          "fecha_nacimiento": "", 
          "codigo_postal": "", 
          "email": "", 
          "telefono": "", 
          "sexo": "", 
          "colonia": "", 
          "calle": "", 
          "numero_exterior": "", 
          "numero_interior": "", 
          "rfc": "", 
          "curp": "" 
        }, 
        "activo":{
          "tipo_auto": "", 
          "modelo": "", 
          "marca": "", 
          "submarca": "", 
          "descripcion_auto": "", 
          "numero_serie": "", 
          "placas": "" 
        },
        "informacion_cotizacion":{
          "cobertura": "", 
          "frecuencia_de_pago": "", 
          "recuperar_cotizacion": "https://Cabsa.com.mx/cotizaciones?id=", 
          "cotizacion_id": "" 
        },
        "informacion_poliza":{
          "origen": "Residente", 
          "aseguradora": "", 
          "cu_aseguradora": "",
          "agente":"",
          "numero_poliza": "", 
          "importe_total": "", 
          "iva": "", 
          "derecho" : "", 
          "prima_neta": "", 
          "recargos": "", 
          "fecha_emision": "", 
          "fecha_inicio": "", 
          "fecha_termino": "" 
        },
        "vendedor":{
          "nombre_vendedor":"", 
          "cu_vendedor":""
        }
    });
    // FIN
    provide('paymentResponse', paymentResponse);
    provide('help', help);
    provide('buttonDisabled', buttonDisabled);
    provide('stepName', stepName);
    provide('message', message);
    provide('showMessage', showMessage);
    provide('validationError', validationError);
    provide('loader', loader);
    provide('lifeIssuanceResponseParams', lifeIssuanceResponseParams)
    provide('arrayCategories', arrayCategories);
    provide('arrayCategoriesDep', arrayCategoriesDep);
    provide('currentProductName', currentProductName);
    provide('titularGender', titularGender);
    provide('currentSubcategoryName', currentSubcategoryName);
    provide('dependentObstetricHistory', dependentObstetricHistory);
    provide('arrayDependents', arrayDependents);
    provide('dependentClinicHistory', dependentClinicHistory);
    provide('dependentData', dependentData);
    provide('responseQuoteRegister', responseQuoteRegister);
    provide('jsonQuotationResumen', responseQuoteInfo);
    provide('jsonQuotationResumen', jsonQuotationResumen);
    provide('showQuestion', showQuestion);
    provide('arrayIdQuestions', arrayIdQuestions);
    provide('currentCategoryName', currentCategoryName);
    provide('activeProviders', activeProviders)
    provide('arrayParamsGlobal', arrayParamsGlobal);
    provide('isLoading', isLoading);
    provide('questionId', questionId);
    provide('answer', answer);
    provide('answers', answers);
    provide('arrayAnswers', arrayAnswers);
    provide('arrayAnswersDependents', arrayAnswersDependents);
    provide('contCategory', contCategory)
    provide('contSteps', contSteps)
    provide('step', step)
    provide('help', help)
    provide('products', products)
    provide('aspects', aspects)
    provide('categories', categories)
    provide('questionsPersonalInfo', questionsPersonalInfo)
    provide('carInfo', carInfo)
    
    provide('issuanceParams', issuanceParams)
    provide('selectedProviderQuote', selectedProviderQuote)
    provide('car_description', car_description)
    provide('selected_products', selected_products)
    provide('issuanceResponseParams', issuanceResponseParams)
    provide('selectedProducts', selectedProducts);
    provide('issuedProducts', issuedProducts);
    provide('issuanceRegistered', issuanceRegistered);
    provide('clientName', clientName);
    provide('policyNumber', policyNumber);
    provide('quoteId', quoteId);
    provide('issuanceId', issuanceId);

    //REFACTORIZAR NOMBRES
    provide('parametrosCotizacion', parametrosCotizacion);
    provide('parametrosEmision', parametrosEmision);
    provide('parametrosPago', parametrosPago);
    provide('parametrosSalesforce', parametrosSalesforce);
    // FIN

    onMounted(async () => {
      var usuario_actual = JSON.parse(sessionStorage.getItem("login"));
      if(usuario_actual){
        parametrosCotizacion.value.usuario_id = usuario_actual.id;
      }
    });

    return {
      step,
      isLoading,
      router,
      route,
      loader
    };
  }

};
</script>
