<template lang="en">
<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
  <loading :active="isLoading" :can-cancel="false" :color="'#bfd243'" :height="100" :width="350" :is-full-page="true" :loader="loader">
  </loading>
  <div class="mx-auto w-full max-w-sm lg:w-96">
    <div>
      <img class="w-20" src="@/assets/images/logo.png" alt="Workflow" />
      <h2 class="mt-6 text-3xl leading-9 font-extrabold text-orange-500">
        {{title}}
      </h2>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w"> {{message}}</p>
      <p class="mt-2 text-sm leading-5 text-gray-600 max-w"> {{email}}</p>
    </div>
    <div class="mt-8">
      <div>
        <div class="mt-6">
          <div class="space-y-6">
            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button type="button" id="btn_agreed" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
                 <router-link :to="{ path: '/login'}" id="router_login" replace>Entendido</router-link>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import User from "@/classes/User.js"
import {
  ref,
  onMounted
} from 'vue';
import {
  useRoute
} from 'vue-router';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "AccountConfirmation",
  components: {
    Loading
  },
  setup() {
    const route = useRoute();
    const isLoading = ref(false);
    const loader = ref('spinner');
    const confirmationToken = ref(route.params.confirmation_token);
    const email = ref(route.params.email);
    const message = ref("");
    const title = ref("");

    onMounted(async () => {
      if (email.value != "" && confirmationToken.value != "" && email.value != null && confirmationToken.value != null && email.value != undefined && confirmationToken.value != undefined) {
        await confirmationAccount();
      } else {
        title.value = "Cuenta no confirmada";
        message.value = "Ocurrió un error al confirmar la cuenta. Intentelo más tarde.";
      }
    })

    async function confirmationAccount() {
      isLoading.value = true;
      User.confirmation(confirmationToken.value, email.value)
        .then(() => {
          title.value = "Cuenta confirmada";
          message.value = "Haz confirmado correctamente la cuenta con la siguiente dirección de correo electrónico: ";
        })
        .catch((error) => {
          title.value = "Cuenta no confirmada";
          let messageError = error.response.data.errores[0];
          message.value = messageError == undefined ? "Ocurrió un error al confirmar la cuenta. Intentelo más tarde." : messageError

        }).finally(() => {
          isLoading.value = false;
        });
    }

  

    return {
      isLoading,
      loader,
      message,
      email,
      title
    };
  }
}
</script>
