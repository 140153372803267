<template>
<div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-xl mt-12">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Resumen
    </h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
      Detalles de tu cotización
    </p>
  </div>
  <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
    <dl class="sm:divide-y sm:divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Nombre </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{full_name}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Servicios cotizados
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{selected_products}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Fecha de nacimiento
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{birth_date}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Sexo </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{gender}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Correo
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{email}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Teléfono
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{phone}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Código Postal </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{postal_code}}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          Auto </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{car_description_resume}}
        </dd>
      </div>
    </dl>
  </div>
  <p style="color: #9fa6b2; font-size: 0.75rem; text-align: center;">Si no estás seguro, puedes regresar o solicitar ayuda en nuestra línea de WhatsApp, haciendo clic <a style="color: black bold" target="_blank" href="https://bit.ly/WhatsAppCabsa"><b>AQUÍ</b></a></p>
  
  <div class="flex justify-between  mt-16">
    <span class="inline-flex rounded-md shadow-sm">
      <button type="button" id="btn_back" @click="back()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Atrás
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm" v-if="available">
      <button type.preve="button" id="btn_quote" @click="next()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
        Cotizar
      </button>
    </span>
  </div>
</div>
</template>

<script>

import {
  inject,
  ref,
  onMounted
} from "vue";
import {
  useRouter
} from 'vue-router';
import axios from "axios";

export default {
  name: "Resume",
  components: {},

  setup() {
    let config = ref({
      headers: {
        'Content-Type': 'application/json'
      }
    }); 
    const multicrapi = process.env.VUE_APP_MULTICR;
    const available = ref(false);
    const router = useRouter()
    const activeProviders = inject('activeProviders');
    const isLoading = inject('isLoading');
    const questionIdGlobal = inject('questionId');
    const answerGlobal = inject('answer');
    const contCategory = inject('contCategory');
    const step = inject('step');
    const contSteps = inject('contSteps');
    const arrayAnswers = inject('arrayAnswers');
    const arrayParamsGlobal = inject('arrayParamsGlobal');
    const parametrosCotizacion = inject('parametrosCotizacion');
    const parametrosSalesforce = inject('parametrosSalesforce');
    const aspects = inject('aspects');
    const email = ref();
    const phone = ref();
    const birth_date = ref();
    const gender = ref();
    const postal_code = ref();
    const full_name = ref({
      names: "",
      last_name: "",
      second_last_name: ""
    });
    const car_description_resume = ref({});

    const car_description = inject('car_description');

    const selected_products = inject('selected_products');
    

    onMounted(async () => {
      isLoading.value = true;
      var car_description_id = "";
      var car_year_id = "";
      var car_type_id = "";
      arrayAnswers.value.forEach(answer => {
        if (answer.question.includes("Nombre(s)")) {
          full_name.value.names = answer.answer;
          parametrosCotizacion.value.cliente.nombres = answer.answer;
          parametrosSalesforce.value.cliente.nombres = answer.answer;
        }
        if (answer.question.includes("Apellido Paterno")) {
          full_name.value.last_name = answer.answer;
          parametrosCotizacion.value.cliente.apellido_paterno = answer.answer;
          parametrosSalesforce.value.cliente.apellido_paterno = answer.answer;
        }
        if (answer.question.includes("Apellido Materno")) {
          full_name.value.second_last_name = answer.answer;
          parametrosCotizacion.value.cliente.apellido_materno = answer.answer;
          parametrosSalesforce.value.cliente.apellido_materno = answer.answer;
        }
        if (answer.question.includes("¿Tu auto es un sedán o pickup?")) {
          car_type_id = answer.answer_id;
          parametrosCotizacion.value.auto.tipo = answer.answer;
          
        }
        if (answer.question.includes("¿Qué modelo (año) es tu auto?")) {
          car_description.value.year = answer.answer;
          car_year_id = answer.answer_id;
          parametrosCotizacion.value.auto.modelo = answer.answer;
          parametrosSalesforce.value.activo.modelo = answer.answer;
        }
        if (answer.question.includes("¿Qué marca es tu vehículo?")) {
          parametrosCotizacion.value.auto.marca = answer.answer;
          parametrosCotizacion.value.auto.marca_id = answer.answer_id;
          parametrosSalesforce.value.activo.marca = answer.answer;
        }

        if (answer.question.includes("Selecciona el modelo de tu auto")) {
          parametrosCotizacion.value.auto.submarca = answer.answer;
          parametrosCotizacion.value.auto.submarca_id = answer.answer_id;
          parametrosSalesforce.value.activo.sub_marca = answer.answer;
        }
        if (answer.question.includes("¿Qué versión es tu auto?")) {
          car_description.value.description = answer.answer;
          car_description_id = answer.answer_id;
          parametrosCotizacion.value.auto.descripcion = answer.answer;
          parametrosCotizacion.value.auto.descripcion_id = answer.answer_id;
          parametrosSalesforce.value.activo.descripcion_auto = answer.answer;
        }
        if (answer.question.includes("Correo electrónico")) {
          email.value = answer.answer;
          parametrosCotizacion.value.cliente.email = answer.answer;
          parametrosSalesforce.value.cliente.email = answer.answer;
        }
        if (answer.question.includes("Código Postal")) {
          postal_code.value = answer.answer;
          parametrosCotizacion.value.cliente.codigo_postal = answer.answer;
          parametrosSalesforce.value.cliente.codigo_postal = answer.answer;
        }
        if (answer.question.includes("Teléfono")) {
          phone.value = answer.answer;
          parametrosCotizacion.value.cliente.telefono = answer.answer;
          parametrosSalesforce.value.cliente.telefono = answer.answer;
        }
        if (answer.question.includes("Cuándo naciste")) {
          birth_date.value = answer.answer;
          parametrosCotizacion.value.cliente.fecha_nacimiento = answer.answer;
          parametrosSalesforce.value.cliente.fecha_nacimiento = answer.answer;
        }
        if (answer.question.includes("tú eres")) {
          gender.value = "Masculino";
          if(answer.answer != "Hombre")
          { 
            gender.value = "Femenino";
          }
          parametrosCotizacion.value.cliente.sexo = gender.value;
          parametrosSalesforce.value.cliente.sexo = gender.value;
        }
      });
      full_name.value = full_name.value.names + " " + full_name.value.last_name + " " + full_name.value.second_last_name;
      car_description_resume.value = car_description.value.year + " " + car_description.value.description;
      selected_products.value = "Auto";
      // if (aspects.value.length < 2) {
      //   if(aspects.value[0] == "1"){
      //     // document.getElementById("car_description_label").style.display = "block";
      //     selected_products.value = "Auto";
      //     // parametrosCotizacion.value.quote.type = "Auto";
      //   }else{
      //     // document.getElementById("car_description_label").style.display = "none";
      //     // selected_products.value = "Vida";
      //     // parametrosCotizacion.value.quote.type = "Vida"
      //   }
      // } else {
      //   // document.getElementById("car_description_label").style.display = "block";
      //   // selected_products.value = "Auto y Vida";
      //   // parametrosCotizacion.value.quote.type = "Auto_Vida";
      // }
      await getActiveProviders();
      await saveQuestionAnswers(arrayAnswers);
      
      if(selected_products.value != "Vida")
      {
        await getCarCodes(car_type_id, car_year_id, car_description_id);
      }else{
        available.value=true;
      }
      isLoading.value = false;
    })

    async function saveQuestionAnswers(array){
      var data = {
        "respuesta": {
          "lista_detallada": array.value
        }
      }

      axios.post(multicrapi + "/respuestas/registrar", data, config.value).then((response) => {
        parametrosCotizacion.value.respuesta_id = response.data.respuesta.id;
        console.log("respuesta de /respuestas/registrar", response.data);
        saveProspect();
      }).catch(error => {
        console.log("Se presentó un errror: ",error)
      });
    }
    async function getCarCodes(value_type, value_model, value_description_id) {
    
      var data = {
        "tipo": value_type,
        "modelo":value_model,
        "descripcion_id":value_description_id
      }

      await axios.get(multicrapi + '/autos/catalogo_apro/obtener_claves',{params: data}, config.value).then((response) => {
        parametrosCotizacion.value.auto.lista_de_claves_de_auto = response.data.respuesta.resultado;
        console.log("respuesta de Api/Catalogo/Apro'",response.data.respuesta);
      }).catch(error => {
        console.log("Se presentó un error; ",error)
      });
    }

    async function getActiveProviders() {
      await axios.get(multicrapi + '/autos/obtener_proveedores_activos', config.value).then((response) => {
        console.log("respuesta al obtener el listado",response.data.respuesta);
        activeProviders.value = response.data.respuesta;
        available.value=true;
      }).catch(error => {
        console.log("Se presentó un error; ",error)
      });
    }

    async function saveProspect(){
      
      axios.post(multicrapi + "/prospecto/registrar", parametrosCotizacion.value, config.value).then((response) => {
        parametrosCotizacion.value.prospecto_id = response.data.respuesta.id;
        console.log("respuesta de /prospecto/registrar", response.data);
        // saveProspectSalesforce();

      }).catch(error => {
        console.log("Se presentó un errror: ",error)
      });
    }

    // async function saveProspectSalesforce(){
    //   axios.post(multicrapi + "/salesforce/registrar_cliente", parametrosSalesforce.value, config.value).then((response) => {
    //     // parametrosCotizacion.value.prospecto_id = response.data.respuesta.id;
    //     console.log("respuesta de /salesforce/registrar_cliente", response.data);
    //   }).catch(error => {
    //     console.log("Se presentó un errror: ",error)
    //   });
    // }

    function back() {
      router.push({
        name: "Questions"
      });
    }

    function next() {
      router.push({
        name: "Quotation"
      });
    }

    return {
      full_name,
      car_description,
      car_description_resume,
      gender,
      phone,
      email,
      postal_code,
      birth_date,
      selected_products,
      activeProviders,
      isLoading,
      questionIdGlobal,
      answerGlobal,
      contCategory,
      step,
      contSteps,
      arrayAnswers,
      parametrosCotizacion,
      parametrosSalesforce,
      arrayParamsGlobal,
      aspects,
      back,
      next,
      available
    }
  }
}
</script>
