<template>
  <div
    class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
  >
    <loading
      :active="isLoading"
      :can-cancel="false"
      :color="'#bfd243'"
      :height="100"
      :width="350"
      :is-full-page="true"
      :loader="loader"
    >
    </loading>
    <div class="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <router-link to="/">
          <img
            class="w-20"
            src="@/assets/images/logo.png"
            alt="Workflow"
        /></router-link>
        <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
          Ingresa a tu cuenta
        </h2>
        <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
          O
          <router-link
            to="/createAccount"
            id="router_register_here"
            class="font-medium text-orange-500 hover:text-orange-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            >registrate aquí gratuitamente</router-link
          >
        </p>
      </div>

      <div class="mt-8">
        <div>
          <!-- <div>
            <p class="text-sm leading-5 font-medium text-gray-700">Ingresa con</p>

            <div class="mt-1 grid grid-cols-3 gap-3">
              <div>
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    aria-label="Sign in with Facebook"
                  >
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </span>
              </div>

              <div>
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    aria-label="Sign in with Twitter"
                  >
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </button>
                </span>
              </div>

              <div>
                <span class="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    aria-label="Sign in with Google"
                  >
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 512 512">
                      <path
                        d="M492.668,211.489l-208.84-0.01c-9.222,0-16.697,7.474-16.697,16.696v66.715  c0,9.22,7.475,16.696,16.696,16.696h117.606c-12.878,33.421-36.914,61.41-67.58,79.194L384,477.589  c80.442-46.523,128-128.152,128-219.53c0-13.011-0.959-22.312-2.877-32.785C507.665,217.317,500.757,211.489,492.668,211.489z"
                      />
                      <path
                        d="M256,411.826c-57.554,0-107.798-31.446-134.783-77.979l-86.806,50.034  C78.586,460.443,161.34,512,256,512c46.437,0,90.254-12.503,128-34.292v-0.119l-50.147-86.81  C310.915,404.083,284.371,411.826,256,411.826z"
                      />
                      <path
                        d="M384,477.708v-0.119l-50.147-86.81c-22.938,13.303-49.48,21.047-77.853,21.047V512  C302.437,512,346.256,499.497,384,477.708z"
                      />
                      <path
                        d="M100.174,256c0-28.369,7.742-54.91,21.043-77.847l-86.806-50.034C12.502,165.746,0,209.444,0,256  s12.502,90.254,34.411,127.881l86.806-50.034C107.916,310.91,100.174,284.369,100.174,256z"
                      />
                      <path
                        d="M256,100.174c37.531,0,72.005,13.336,98.932,35.519c6.643,5.472,16.298,5.077,22.383-1.008  l47.27-47.27c6.904-6.904,6.412-18.205-0.963-24.603C378.507,23.673,319.807,0,256,0C161.34,0,78.586,51.557,34.411,128.119  l86.806,50.034C148.202,131.62,198.446,100.174,256,100.174z"
                      />
                      <path
                        d="M354.932,135.693c6.643,5.472,16.299,5.077,22.383-1.008l47.27-47.27  c6.903-6.904,6.411-18.205-0.963-24.603C378.507,23.672,319.807,0,256,0v100.174C293.53,100.174,328.005,113.51,354.932,135.693z"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
          </div> -->

          <!-- <div class="mt-6 relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-500"> O continúa con </span>
            </div>
          </div> -->
        </div>

        <div class="mt-6">
          <form @submit="login($event)" class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium leading-5 text-gray-700"
                >Correo electrónico</label
              >
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="input_email"
                  type="email"
                  v-model="email"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <span>{{ emailError }}</span>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium leading-5 text-gray-700"
              >
                Contraseña
              </label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="input_password"
                  type="password"
                  v-model="password"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <span>{{ passwordError }}</span>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                  id="check_remember_me"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 text-orange-500 transition duration-150 ease-in-out"
                />
                <label
                  for="remember_me"
                  class="ml-2 block text-sm leading-5 text-gray-900"
                  >Recuérdame</label
                >
              </div>

              <div class="text-sm leading-5">
                <router-link
                  to="/accountRecover"
                  id="router_forgot_password"
                  class="font-medium text-orange-500 hover:text-orange-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                  >Olvidé mi contraseña</router-link
                >
              </div>
            </div>

            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  id="btn_login"
                  class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out"
                >
                  Iniciar
                </button>
              </span>
              
            </div>
          </form>
          <br><br><router-link to="/">
            <button id="btn_regresar" class="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition duration-150 ease-in-out">
              Volver
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useField, useForm } from "vee-validate";
import User from "@/classes/User";
export default {
  name: "LoginForm",
  components: {
    Loading,
  },
  setup() {
    const multicrapi = process.env.VUE_APP_MULTICR;
    const router = useRouter();
    const isLoading = ref(false);
    const loader = ref("spinner");
    //validations
    // Define a validation schema
    const simpleSchema = {
      email(value) {
        // if the field is empty
        if (!value) {
          return "Campo requerido";
        }
        // if the field is not a valid email
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          return "El formato no coincide con un correo válido";
        }
        // All is good
        return true;
      },
      password(value) {
        if (!value) {
          return "Campo requerido";
        }
        if (value.length < 6) {
          return "El campo debe tener minímo 6 caracteres";
        }
        if (value.length > 30) {
          return "El campo debe tener máximo de 30 caracteres";
        }
        // All is good
        return true;
      },
    };
    // Create a form context with the validation schema
    useForm({
      validationSchema: simpleSchema,
    });
    // No need to define rules for fields
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } = useField("password");
    async function login(e) {
      e.preventDefault();
      if (
        email.value != "" &&
        password.value != "" &&
        email.value != null &&
        password.value != null &&
        email.value != undefined &&
        password.value != undefined
      ) {
        isLoading.value = true;
        let data = {
          email: email.value,
          password: password.value
        };
        let config = {
          "Content-Type": "application/json",
        };
        await axios
          .post(multicrapi + "/usuario/iniciar_sesion", data, config)
          .then((respuesta) => {
            if (respuesta.data.errores.length == 0){
              let auth = {
                procesado: respuesta.data.respuesta.procesado,
                mensaje: respuesta.data.respuesta.mensaje,
                token: respuesta.data.respuesta.token_auth,
                created_at: respuesta.data.respuesta.obj.created_at,
                updated_at: respuesta.data.respuesta.obj.updated_at,
                rol: respuesta.data.respuesta.obj.rol,
                email: respuesta.data.respuesta.obj.email,
                id: respuesta.data.respuesta.obj.id,
                persona: respuesta.data.respuesta.obj.persona,
                usuario_actual: respuesta.data.respuesta.obj.persona.nombres + " " + respuesta.data.respuesta.obj.persona.apellido_paterno,
                image: ""
              };
              sessionStorage.setItem("login", JSON.stringify(auth));
              Swal.fire({
                title: "Inicio de sesión exitoso",
                text: 'Bienvenido a Cabsa, '+auth.usuario_actual,
                icon: "success"
              });
              User.getUserImage(auth.token)
              .then((resp) => {
                if (resp.status == 200) {
                  auth.image = resp.config.url;
                }
              })
              .catch((err) => {
                console.log(err);
              });
              checkRole(respuesta.data.respuesta.obj.rol);
            }else{
              Swal.fire({
                title: "Aviso",
                text: respuesta.data.errores,
                icon: "warning",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Aviso",
              html: `<span style="font-weight: bold; color:red">${error.response.data.errores}</span>`,
              icon: "error",
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        Swal.fire({
          title: "Aviso",
          text: "Los campos correo y contraseña son requeridos",
          icon: "warning",
        });
      }
    }
    function checkRole(role){
      switch (role) {
        case "Super_Administrador":
          router.push({name: "Dashboard"});
          break;
        case "Administrador":
          router.push({name: "Dashboard"});
          break;
        case "Empleado":
          router.push({name: "Dashboard"});
          break;
        case "Cliente":
            router.push({name: "Customer Main"});
            break;
        case "Vendedor":
            router.push({name: "Dashboard"});
            break;
        default:
          router.push({name: "Main Page"});
          break;
      }
    }
    return {
      login,
      email,
      password,
      isLoading,
      loader,
      emailError,
      passwordError,
    };
  },
};
</script>

<style>
span {
  @apply text-sm text-red-500;
}
</style>