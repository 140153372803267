import axios from "axios";
import { getUserAuthInfo } from "@/helpers/auth.js";

var multicr_api = process.env.VUE_APP_MULTICR;
var config = {
    'Content-Type': 'application/json'
};
export default class User {
    names = null;
    paternalName = null;
    maternalName = null;
    gender = null;
    cellphone = null;
    email = null;
    password = null;
    role = null;

    constructor(names, paternalName, maternalName, gender, cellphone, email, password, role) {
        this.names = names;
        this.paternalName = paternalName;
        this.maternalName = maternalName;
        this.gender = gender;
        this.cellphone = cellphone;
        this.email = email;
        this.password = password;
        this.role = role;
    }

    static async recover(email) {
        let data = {
            email: email
        };
        const response = await axios.post(multicr_api + "/usuario/solicitar_cambio_contraseña", data, config);
        return await response;
    }
    async save() {
        let data = {
          email: this.email,
          password: this.password,
          persona: {
            apellido_paterno: this.paternalName,
            apellido_materno: this.maternalName,
            nombres: this.names,
            sexo: this.gender,
            telefono: this.cellphone
          },
          rol: this.role
        };
        const response = await axios.post(multicr_api + "/usuario/crear_cuenta", data, config);
        return await response;
    }

    async createAccount() {
        let data = {
            token_auth: (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).token : '',
            email: this.email,
            password: this.password,
            persona: {
              apellido_paterno: this.paternalName,
              apellido_materno: this.maternalName,
              nombres: this.names,
              sexo: this.gender,
              telefono: this.cellphone,
            },
            rol: this.role
        };
        return await axios.post(multicr_api + "/usuario/crear_cuenta", data, config);
    }

    static async changePassword(password, confirmPassword, token, email) {
      let data = {
        email: email,
        password: password,
        confirmar_password: confirmPassword,
        reset_password_token: token
      };
      const response = await axios.post(multicr_api + "/usuario/confirmar_nueva_contraseña", data, config);
      return await response;
    }

    static async changePasswordDashboard(password, confirmPassword) {
      let data = {
        password: password,
        confirmar_password: confirmPassword,
        token_auth: (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).token : '',
      };

      const response = await axios.post(multicr_api + "/usuario/cambiar_contraseña", data, config);
      return await response;
    }


    static async updateUserInfo(nombres,apellido_paterno,apellido_materno,email,telefono) {
 

      // const auth = getUserAuthInfo();
      const user = {
        "token_auth": (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).token : '',
        // "user_id": id,
        "email": email,
        // "status": is_active.toString(),
        "datos_personales": {
          "apellido_paterno": apellido_paterno,
          "apellido_materno": apellido_materno,
          "nombres": nombres,
          "sexo": (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).persona.sexo : '',
          "telefono": telefono
        },
        "rol": (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).rol : '',
      }

      const response = await axios.post(multicr_api + "/perfil/actualizar_datos", user, config);
      return await response;
    }

    static async getInfo() {
      return getUserAuthInfo();
    }

    static async getUserImage(tokenAuth = "") {
      let token = "";
      const auth = getUserAuthInfo();
      if (tokenAuth == "" && auth == "") {
          token = auth.token;
      } else {
          token = tokenAuth;
      }

      const response = axios.get(`${multicr_api}/perfil/obtener_foto_de_perfil?token_auth=${token}`).then((resp) => { return resp });
      return await response;
    }

    static async setUserImage(file, extension) {
      const auth = getUserAuthInfo();
      const image = {
          "token_auth": auth.token,
          "user_id": auth.id,
          "extension": extension,
          "file": file
      }

      const response = await axios.post(multicr_api + "/perfil/cargar_foto_de_perfil", image, config);
      return await response;
    }

    static async confirmation(token, email) {
        let data = {
            confirmation_token: token,
            email: email
        };
        const response = await axios.get(multicr_api+ "/usuario/confirmar_cuenta", { params: data }, config);
        return await response;
    }


    async updateUserInfoEmployee(info, user_id) {
 
      const user = {
        "token_auth": (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).token : '',
        "usuario_id": user_id,
        "email": info.email,
        "rol": info.rol,
        "datos_personales": {
          "apellido_paterno": info.apellido_paterno,
          "apellido_materno": info.apellido_materno,
          "nombres": info.nombres,
          "sexo": this.sexo,
          "telefono": info.telefono
        }
      }

      const response = await axios.post(multicr_api + "/empleados/actualizar_datos", user, config);
      return await response;
    }


    async updateUserStatus(status) {
        const user = {
          "token_auth": (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).token : '',
          "email": this.email,
          "status": status
        }
        return await axios.post(multicr_api + "/empleados/actualizar_status", user, config);
    }
}