<template>
  <h3>IV. TRANSFERENCIA DE DATOS</h3>
  <p>
    Cabsa podrá transferir sus Datos Personales a terceros para cumplir los
    acuerdos de servicios ofertados, comprometiéndose a respetar en todo momento
    por nosotros y los terceros el presente Aviso de Privacidad. Cabsa se cerciorara 
    de que los terceros hayan emitido igualmente su correspondiente Aviso de Privacidad, 
    cumpliendo así con la Ley Federal de Protección de Datos Personales en Posesión de 
    Particulares. Los Datos Personales podrán ser transferidos a:
  </p>
  <p>
    Las empresas e instituciones pertenecientes a Cabsa. Socios comerciales como compañías aseguradoras, 
    Grupo Nacional Provincial SAB de CV, Axa Seguros SA de CV, Aba Seguros SA de CV, HDI Seguros SA de CV,
    Quálitas Compañía de Seguros SAB de CV , Mapfre Tepeyac SA, entre otras.
  </p>
  <p>
    Todos los terceros mencionados pueden participar dentro de la cadena y
    proceso bajo los cuales CABSA Seguros brinda los servicios ofertados y que
    son necesarios para los procesos de cotización, emisión de pólizas y
    resolución en la atención de siniestros.
  </p>
</template>
