<template>

<div id="div_resumen_pago" class="rounded-lg shadow-lg bg-aKingBlue p-8 m-8 flex flex-wrap items-center justify-around mb-12">
  <p style="font-size: 1.275rem;" class="text-3xl m-4 ">Resumen</p>
  <div class="min-w-xs flex justify-around flex-wrap items-center">
    <div class="m-4" id="provider_img">
    </div>
    <div class="m-4" id="car_description">
    </div>
    <div class="m-4" id="quote_coverage">
    </div>
    <div class="m-4" id="quote_payment_frequency">
    </div>
    <div class="m-4" id="quote_price">
    </div>
  </div>
  <div class="min-w-xs flex justify-around flex-wrap items-center">
    <p style="font-size: 1.275rem;" class="text-3xl text-center text-aKingBlue w-full mt-12 folioEmision" id="issuance_id"></p>
  </div>
  <div class="min-w-xs flex justify-around flex-wrap items-center">
    <p style="font-size: 1.275rem;" class="text-3xl text-center text-aKingBlue w-full mt-12 numeroPolizaEmision" id="number_policy"></p>
  </div>
</div>
<div class="sm:flex sm:flex-col sm:align-center flex flex-wrap items-center p-5 justify-center mt-5 ">
  <!-- <div id="div_whatsapp" class="sm:w-4/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle ">
    <button type="button" id="btn_share_quote" @click="sendPaymentLinkByMessage()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
      Compartir enlace de pago al WhatsApp
    </button>
  </div> -->
  <button 
    id="downloadPdfPayment" 
    type="button" 
    v-on:click="downloadPolicyPdf" 
    class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150" 
    style="display:none;">
      Descargar poliza
  </button>
  <div id="div_terminal_externa" style="display:none;" class="sm:w-4/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle ">
    <br/><br/><button type="button" @click="getPaymentUrl()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
      Pagar Póliza
    </button>
  </div>
  <div id="div_poliza_pagada" style="display:none;" class="sm:w-4/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle ">
    <br/><br/>
    <p style="font-size: 1.275rem;" class="text-3xl text-center text-aKingBlue w-full mt-12 folioEmision">PÓLIZA PAGADA</p>
  </div>
  <div id="div_registro_invalido" style="display:none;" class="sm:w-4/12 w-full ml-2 mr-2 rounded-md shadow-sm align-middle ">
    <br/><br/>
    <p style="font-size: 1.275rem;" class="text-3xl text-center text-aKingBlue w-full mt-12 folioEmision">NO EXISTE PÓLIZA PARA PAGAR</p>
  </div>
</div>

<div id="div_terminal" style="display:none;" class="rounded-lg shadow-lg bg-aKingBlue p-8 m-8 flex flex-wrap items-center justify-around mb-12">
  <form @submit="makePayment($event)">
    <div class="">
      <div class="w-full">
        <label class="font-semibold">Tipo de tarjeta</label>
        <div class="pb-5">
          <select v-model="mark" class="select" id="selectMark" title="Selecciona un valor de la lista">
            <option disabled value="" selected>Seleccionar</option>
            <option v-for="mark in arrayMarkCards" :key="mark.value" :value="mark.value" :name="mark.value">{{mark.mark}}</option>
          </select>
          <p class="text-red-500 text-xs italic mt-5">{{errorMessageType}}</p>
        </div>
      </div>
      <div class="w-full">
        <label class="font-semibold">Banco</label>
        <div class="pb-5">
          <select v-model="bank" class="select form-control" id="selectBank" title="Selecciona un valor de la lista">
            <option disabled value="" >Seleccionar</option>
            <option v-for="bank in arrayBanks" :key="bank.value" :value="bank.value" :name="bank.value">{{bank.nombre}}</option>
          </select>
          <p class="text-red-500 text-xs italic mt-5">{{errorMessageBank}}</p>
        </div>
      </div>
      <div class="w-full">
        <label class="font-semibold">Nombre en tarjeta</label>
        <div class="pb-5">
          <input type="text" id="input_titularName" class="form-control input" placeholder="Juan Pérez López" v-model="titularName"  />
          <p class="text-red-500 text-xs italic mt-5">{{errorMessageHolderName}}</p>
        </div>
      </div>
      <div class="w-full">
        <label class="font-semibold">Número de tarjeta</label>
        <div class="pb-5">
          <input type="text" id="input_cardNumber" class="form-control input" placeholder="0000 0000 0000 0000" v-model="cardNumber" v-mask="'#### #### #### ####'" />
          <p class="text-red-500 text-xs italic mt-5">{{errorMessageCard}}</p>
        </div>
      </div>
      <div class="w-full">
        <label class="font-semibold">Fecha de expiración</label>
      </div>

      <div class="w-6/12 inline-flex">
        <select v-model="monthExpirationDate" class="select" id="input_monthExpirationDate" title="Selecciona un valor de la lista">
          <option disabled value="">Seleccionar</option>
          <option v-for="month in filterMonths.months" :key="month.value" :value="month.value" :name="month.value">{{month.value}} - {{month.month}}</option>
        </select>
      </div>

      <div class="w-5/12 inline-flex">
        <select v-model="yearExpirationDate" title="Selecciona un valor de la lista" class="select" id="input_yearExpirationDate" @change="validateMonths">
          <option disabled value="">Seleccionar</option>
          <option v-for="year in years.years" :key="year.value" :value="year.value" :name="year.value">{{year.year}}</option>
        </select>
        
      </div>
      <p class="text-red-500 text-xs italic mt-5">{{errorMessageMonth}} {{errorMessageYear}}</p>
      
      <br/>
      <div class="w-3/12">
        <label class="font-semibold">CVV</label>
        <div>
          <input type="password" class="form-control input" id="input_cvv" v-model="cvv" placeholder="000" maxlength="4" minlength="3"/>
        </div>
      </div>
      <p class="text-red-500 text-xs italic mt-5">{{errorMessageCvv}}</p>
      <div class="justify-between  mt-16">
        <span class="rounded-md shadow-sm">
          <button type="submit" id="btn_pay" class="btn focus:shadow-outline-orange active:bg-orange-700">
            Realizar pago
          </button>
        </span>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import {
  useRouter
} from 'vue-router';
import Swal from "sweetalert2";
import {
  ref,
  inject,
  // provide,
  onMounted
} from "vue";
import axios from "axios";
import markCards from "@/markCards.json";
import banks from "@/banks.json";
import banks_atlas from "@/banks_atlas.json";
import {
  mask
} from 'vue-the-mask'
//import SendEmail from "@/classes/SendEmail.js";
import Payment from "@/classes/Payment.js";

import {
  useForm,
  useField
} from 'vee-validate';
import * as yup from 'yup';
import Customer from '@/classes/customer.js';

export default {
  name: "WayOfPayment",
  directives: {
    mask
  },
  setup() {
    const multicrapi = process.env.VUE_APP_MULTICR;
    let config = ref({
      headers: {
        'Content-Type': 'application/json'
      }
    }); 
    const router = useRouter();
    const arrayMarkCards = ref([]);
    const arrayBanks = ref([]);

    const monthExpirationDate = ref("");
    const yearExpirationDate = ref("");
    
    const errorMessageType = ref();
    const errorMessageBank = ref();
    const errorMessageHolderName = ref();
    const errorMessageCard = ref();
    const errorMessageMonth = ref();
    const errorMessageYear = ref();
    const errorMessageCvv = ref();

    const months = ref({});

    const urlPolicyPdf = ref("");
    
    months.value = {
      "months": [
        {"value": "01","month": "Enero"},
        {"value": "02","month": "Febrero"},
        {"value": "03","month": "Marzo"},
        {"value": "04","month": "Abril"},
        {"value": "05","month": "Mayo"},
        {"value": "06","month": "Junio"},
        {"value": "07","month": "Julio"},
        {"value": "08","month": "Agosto"},
        {"value": "09","month": "Septiembre"},
        {"value": "10","month": "Octubre"},
        {"value": "11","month": "Noviembre"},
        {"value": "12","month": "Diciembre"}
      ]
    }
    const filterMonths = ref();
    filterMonths.value = {
      "months": [
        {"value": "01","month": "Enero"},
        {"value": "02","month": "Febrero"},
        {"value": "03","month": "Marzo"},
        {"value": "04","month": "Abril"},
        {"value": "05","month": "Mayo"},
        {"value": "06","month": "Junio"},
        {"value": "07","month": "Julio"},
        {"value": "08","month": "Agosto"},
        {"value": "09","month": "Septiembre"},
        {"value": "10","month": "Octubre"},
        {"value": "11","month": "Noviembre"},
        {"value": "12","month": "Diciembre"}
      ]
    }
    const years = ref({});
    years.value = {
      "years": [
        {"value": "22","year": 2022},
        {"value": "23","year": 2023},
        {"value": "24","year": 2024},
        {"value": "25","year": 2025},
        {"value": "26","year": 2026},
        {"value": "27","year": 2027},
        {"value": "28","year": 2028},
        {"value": "29","year": 2029},
        {"value": "29","year": 2030},
        {"value": "29","year": 2031},
        {"value": "29","year": 2032},
        {"value": "29","year": 2033},
        {"value": "29","year": 2034},
      ]
    };
    const step = inject("step");
    const isLoading = inject('isLoading');
    
    const selectedProviderQuote = inject('selectedProviderQuote');
    const issuanceResponseParams = inject('issuanceResponseParams');
    const issuanceId = inject('issuanceId');
    const issuanceRegistered = inject('issuanceRegistered');
    
    const parametrosEmision = inject('parametrosEmision');
    const parametrosPago = inject('parametrosPago');
    const paymentResponse = inject('paymentResponse');
    

    // Define a validation schema
    const schema = yup.object({});

    // Create a form context with the validation schema
    useForm({
      validationSchema: schema,
    });

    // No need to define rules for fields
    const {
      value: mark,
      errorMessage: markError
    } = useField('mark');
    const {
      value: bank,
      errorMessage: bankError
    } = useField('bank');
    titularNameError
    const {
      value: titularName,
      errorMessage: titularNameError
    } = useField('titularName');
    const {
      value: cardNumber,
      errorMessage: cardNumberError
    } = useField('cardNumber');
    const {
      value: cvv,
      errorMessage: cvvError
    } = useField('cvv');

    arrayMarkCards.value = markCards.marks;
    
    
    
    onMounted( async () => {
      isLoading.value = true;
      if (parametrosEmision.value.proveedor == "Atlas"){
        arrayBanks.value = banks_atlas.banks;
      }else{
        arrayBanks.value = banks.banks;
      }
        
      document
        .getElementById("input_titularName")
        .addEventListener("keyup", () => {
          var regex = /^[a-zA-ZÀ-ÿ ]+$/;
          if (regex.test(titularName.value) != true) {
            titularName.value = titularName.value.replace(/[^a-zA-ZÀ-ÿ@]+/, "");
          }
        });
      document
        .getElementById("input_cvv")
        .addEventListener("keyup", () => {
          var regex = /^[0-9]+$/;
          if (regex.test(cvv.value) != true) {
            cvv.value = cvv.value.replace(/[^0-9@]+/, "");
          }
        });
      
      var paramsString = "q=URLUtils.searchParams&"+window.location.search
      const urlParams = new URLSearchParams(paramsString)
      issuanceId.value = parametrosPago.value.informacion.emision_id
      if(urlParams.get('?id') != null){
        issuanceId.value = urlParams.get('?id')
        issuanceRegistered.value.issuance_id = issuanceId.value;
      }
      if(issuanceId.value != ""){
        await getIssuanceSaved(issuanceId.value); 
      }
      isLoading.value = false;
      //else{
      //   const payment = new Payment();
      //   payment.setPaymentResume(parametrosPago.value);
      //   if (parametrosEmision.value.proveedor == "GNP" || parametrosEmision.value.proveedor == "Chubb") {
      //     document.getElementById("div_terminal").style.display = "none";
      //     document.getElementById("div_terminal_externa").style.display = "block";
      //   }
      // }
    })

    function getPaymentUrl() {
      isLoading.value = true;
      
      let parameter = "";
      if (parametrosPago.value.proveedor == "Gnp"){
        parameter = {
          "proveedor": parametrosPago.value.proveedor,
          "informacion":{
            "numero_cotizacion": parametrosPago.value.informacion.cotizacion.numero_cotizacion,
            "primer_importe": parametrosPago.value.informacion.cotizacion.primer_importe
          }
        };
      }
      if(parametrosPago.value.proveedor == "Chubb") {
        parameter = {
          "proveedor": parametrosPago.value.proveedor,
          "informacion":{
            "numero_poliza": parametrosPago.value.informacion.emision.numero_poliza,
            "frecuencia_de_pago": parametrosPago.value.informacion.auto.frecuencia_de_pago,
            "nombres": parametrosPago.value.informacion.cliente.nombres,
            "apellido_paterno": parametrosPago.value.informacion.cliente.apellido_paterno,
            "apellido_materno": parametrosPago.value.informacion.cliente.apellido_materno,
            "email": parametrosPago.value.informacion.cliente.email
          }
        }
      }
      axios.post(multicrapi + "/autos/generar_enlace_de_pago", parameter, config.value).then((response) => {
        window.location.href = response.data.respuesta.url_terminal_pagos;
      }).catch((error) => {
        console.log("Se presento un error: ", error);
      })
      .finally(() => {
        isLoading.value = false;
      });
    }

    async function getIssuanceSaved(emision_id) {
      let parameter = "";

      await axios.get(multicrapi + "/autos/recuperar_emision?emision_id="+emision_id, {}, config.value ).then((issuance_result) => {
        issuanceResponseParams.value = issuance_result.data.respuesta;
        urlPolicyPdf.value = issuance_result.data.respuesta.url_descarga_pdf
        document.getElementById("downloadPdfPayment").style.display = "block";
        // if(issuance_result.data.respuesta.mensaje == "Póliza pagada"){
        //   document.getElementById("div_terminal").style.display = "none";
        //   document.getElementById("div_whatsapp").style.display = "none";
        //   document.getElementById("div_poliza_pagada").style.display = "block";
        // }
        if(issuance_result.data.respuesta.mensaje == "No se encontro póliza para pagar"){
          document.getElementById("div_terminal").style.display = "none";
          document.getElementById("div_whatsapp").style.display = "none";
          document.getElementById("div_resumen_pago").style.display = "none";
          
          document.getElementById("div_registro_invalido").style.display = "block";
        }
        parametrosEmision.value.informacion = issuance_result.data.respuesta.parametros;
        parametrosPago.value.proveedor = issuance_result.data.respuesta.proveedor;
        parametrosPago.value.informacion.auto = parametrosEmision.value.informacion.auto;
        parametrosPago.value.informacion.cliente = parametrosEmision.value.informacion.cliente;
        parametrosPago.value.informacion.cotizacion = parametrosEmision.value.informacion.cotizacion;
        if(issuance_result.data.respuesta.proveedor == "Atlas" && issuance_result.data.respuesta.mensaje != "Póliza pagada"){
          parametrosPago.value.informacion.cotizacion.control_id = issuance_result.data.respuesta.informacion_cotizacion.control_id;
        }
        parametrosPago.value.informacion.cotizacion_id = parametrosEmision.value.informacion.cotizacion_id;
        parametrosPago.value.informacion.cotizacion_proveedor_id = parametrosEmision.value.informacion.cotizacion_proveedor_id;
        parametrosPago.value.informacion.emision_id = emision_id;
        parametrosPago.value.informacion.prospecto_id = parametrosEmision.value.informacion.prospecto_id;
        parametrosPago.value.informacion.usuario_id = parametrosEmision.value.informacion.usuario_id;
        parametrosPago.value.informacion.respuesta_id = parametrosEmision.value.informacion.respuesta_id;
        
        parametrosPago.value.informacion.emision.numero_poliza = issuanceResponseParams.value.informacion_emision.numero_poliza;
        parametrosPago.value.informacion.emision.numero_endoso = issuanceResponseParams.value.informacion_emision.numero_endoso;
        parametrosPago.value.informacion.emision.facturas = issuanceResponseParams.value.informacion_emision.recibos;
        parametrosPago.value.informacion.emision.primer_importe = issuanceResponseParams.value.informacion_emision.primer_importe;
        parametrosPago.value.informacion.emision.importe_subsecuente = issuanceResponseParams.value.informacion_emision.importe_subsecuente;
        parametrosPago.value.informacion.emision.importe_total = issuanceResponseParams.value.informacion_emision.importe_total;
        parametrosPago.value.informacion.emision.prima_neta = issuanceResponseParams.value.informacion_emision.prima_neta;
        parametrosPago.value.informacion.emision.impuesto = issuanceResponseParams.value.informacion_emision.impuesto;
        
        const payment = new Payment();
        console.log("ENTROOOOO")
        payment.setPaymentResume(parametrosPago.value);
      }).catch((error) => {
        console.log("error: ", error.message);
        document.getElementById("div_terminal").style.display = "none";
        document.getElementById("div_whatsapp").style.display = "none";
        document.getElementById("div_resumen_pago").style.display = "none";
        
        document.getElementById("div_registro_invalido").style.display = "block";
      }).finally(() => {isLoading.value = false});

      if (parametrosPago.value.proveedor == "Gnp"){
        parameter = {
          "proveedor": parametrosPago.value.proveedor,
          "informacion":{
            "numero_cotizacion": parametrosPago.value.informacion.cotizacion.numero_cotizacion,
            "primer_importe": parametrosPago.value.informacion.cotizacion.primer_importe
          }
        };
      }
      if(parametrosPago.value.proveedor == "Chubb") {
        parameter = {
          "proveedor": parametrosPago.value.proveedor,
          "informacion":{
            "numero_poliza": parametrosPago.value.informacion.emision.numero_poliza,
            "frecuencia_de_pago": parametrosPago.value.informacion.auto.frecuencia_de_pago,
            "nombres": parametrosPago.value.informacion.cliente.nombres,
            "apellido_paterno": parametrosPago.value.informacion.cliente.apellido_paterno,
            "apellido_materno": parametrosPago.value.informacion.cliente.apellido_materno,
            "email": parametrosPago.value.informacion.cliente.email
          }
        }
      }

      if(parametrosPago.value.proveedor == "Chubb" || parametrosPago.value.proveedor == "Gnp") {
        await axios.post(multicrapi + "/autos/generar_enlace_de_pago", parameter, config.value).then((response) => {
          window.location.href = response.data.respuesta.url_terminal_pagos;
        }).catch((error) => {
          console.log("Se presento un error: ", error);
        })
        .finally(() => {
          isLoading.value = false;
        });
      }
    }

    async function sendPaymentLinkByMessage(){
      let cliente = "", phone = "", auto = "", año = "";
      if (parametrosPago.value != null && parametrosPago.value != "") {
        cliente = parametrosPago.value.informacion.cliente.nombres + " "+ parametrosPago.value.informacion.cliente.apellido_paterno + " "+parametrosPago.value.informacion.cliente.apellido_materno;
        phone = parametrosPago.value.informacion.cliente.telefono;
        año = parametrosPago.value.informacion.auto.modelo;
        auto = parametrosPago.value.informacion.auto.descripcion;
        let payment_frequency = parametrosPago.value.informacion.auto.frecuencia_de_pago;
        let coverage = parametrosPago.value.informacion.auto.cobertura;
        let aseguradora = parametrosPago.value.proveedor;
        let folio = parametrosPago.value.informacion.emision_id;
        let carInfo = año + " " + auto;
        
        await Swal.fire({
          title: 'Enviar link de pago al WhatsApp',
          input: "tel",
          inputAttributes: {
            value: phone
          },
          inputLabel: 'Telefono',
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Es necesario ingresar un número telefonico'
            }
            if (value.length != 10) {
              return 'Ingrese un número telefonico con 10 dígitos'
            }
            let mensaje = "https://api.whatsapp.com/send?phone=+521+"+value+"&text=%C2%A1Hola, "+cliente+"!%20Gracias%20por%20cotizar%20en%20Cabsa%20Tu%20Seguro%20Online,%20la%20poliza%20de%20tu%20Auto%20con%20"+aseguradora+",%20te%20comparto%20la%20informacion%20de%20pago%20con%20folio:%20"+folio+"%20%0d%20-Auto:%20"+carInfo+"%20-Forma%20de%20pago:%20"+payment_frequency+"%20-Cobertura:%20"+coverage+".%0d%20Solo%20entra%20en%20este%20enlace:%20https://Cabsa.com.mx/pago-en-linea?id="+folio;
            window.open(mensaje);
          }
        })
      } else {
        Swal.fire(
          "Aviso",
          "No se encontro información, solicite apoyo de Soporte técnico.",
          "info"
        );
      }
    }

    async function makePayment(e) {
      errorMessageType.value = "";
      errorMessageBank.value = "";
      errorMessageHolderName.value = "";
      errorMessageCard.value = "";
      errorMessageMonth.value = "";
      errorMessageYear.value = "";
      errorMessageCvv.value = "";
      var recotizacion = true;
      const payment = new Payment();

      e.preventDefault();
      console.log("card ", cardNumberError);
      if (document.getElementById("selectMark").value != '' && document.getElementById("selectBank").value != ''&& document.getElementById("input_titularName").value != '' &&
      (document.getElementById("input_titularName").value.length > 11 || document.getElementById("input_titularName").value.length < 40) &&
      document.getElementById("input_cardNumber").value != '' &&
      ((document.getElementById("selectMark").value != 'AmericanExpress' || document.getElementById("input_cardNumber").value.length == 16) || (document.getElementById("selectMark").value == 'AmericanExpress' || document.getElementById("input_cardNumber").value.length == 15)) &&
      document.getElementById("input_monthExpirationDate").value != ''&&
      document.getElementById("input_yearExpirationDate").value != ''&&
      document.getElementById("input_cvv").value != '' &&
      ((document.getElementById("selectMark").value != 'AmericanExpress' && document.getElementById("input_cvv").value.length == 3) || (document.getElementById("selectMark").value == 'AmericanExpress' &&  document.getElementById("input_cvv").value.length == 4))) 
      {
        isLoading.value = true;
        
        parametrosPago.value.informacion.tarjeta = payment.encryptBankCard(mark.value, bank.value, titularName.value, cardNumber.value.replace(" ", ""), monthExpirationDate.value, yearExpirationDate.value, cvv.value)
        
        if (parametrosPago.value.proveedor != "Axa" && parametrosPago.value.proveedor != "Hdi" && parametrosPago.value.proveedor != "Zurich" && parametrosPago.value.proveedor != "Anaseguros" && parametrosPago.value.proveedor != "Atlas") {
          if (parametrosPago.value.informacion.emision == null || parametrosPago.value.informacion.emision == "") {
            Swal.fire(
              "Aviso",
              "No se encontrarón los datos de la emisión requeridos para continuar",
              "info"
            );
            isLoading.value = false;
            return;
          }
          
          return await axios.post(multicrapi + '/autos/realizar_pago_en_linea', parametrosPago.value, config.value).then((response) => {
            paymentResponse.value = response.data.respuesta;
            //sendEmail();
            
            return response;
          }).catch((error) => {
            console.log("Se presento un error al realizar el pago: ", error.response.data.respuesta.mensaje);
            Swal.fire({
              title: "Aviso",
              text: error.response.data.respuesta.mensaje,
              icon: "error",
            });
            isLoading.value = false;
          })
          .finally(() => {
            isLoading.value = false;
            next();
          });
        } else {
          if (parametrosPago.value.proveedor == "Axa") {
            await axios.post(multicrapi + '/autos/solicitar_recotizacion', parametrosPago.value, config.value).then((response) => {
              paymentResponse.value = response.data.respuesta;
              recotizacion = true;
            }).catch((error) => {
              console.log("Se presento un error al realizar el pago: ", error.response.data.errores);
              Swal.fire({
                title: "Aviso",
                text: error.response.data.errores,
                icon: "error",
              });
              recotizacion = false;
              isLoading.value = false;
              return;
            })
            .finally(() => {});
          }
          if(recotizacion == true){
            return await axios.post(multicrapi + '/autos/solicitar_emision', parametrosPago.value, config.value).then((response) => {
              paymentResponse.value = response.data.respuesta;
              parametrosPago.value.informacion.emision.numero_poliza = response.data.respuesta.informacion_emision.numero_poliza;
              
              // registerResponsePayment(paymentResponse.value, false);
            
              const customer = new Customer();
            
              customer.register(
                parametrosEmision.value.informacion.cliente.email,
                parametrosPago.value.informacion.emision.numero_poliza,
                parametrosEmision.value.informacion.cliente.nombres, 
                parametrosEmision.value.informacion.cliente.apellido_paterno, 
                parametrosEmision.value.informacion.cliente.apellido_materno, 
                parametrosEmision.value.informacion.cliente.sexo, 
                parametrosEmision.value.informacion.cliente.telefono, 
                parametrosEmision.value.informacion.cliente.rfc, 
                parametrosEmision.value.informacion.cliente.fecha_nacimiento, 
                parametrosEmision.value.informacion.cliente.calle, 
                parametrosEmision.value.informacion.cliente.colonia, 
                parametrosEmision.value.informacion.cliente.codigo_postal, 
                parametrosEmision.value.informacion.cliente.numero_exterior, 
                "Fisica", 
                ""
              );
              //sendEmail();
            }).catch((error) => {
              console.log("Se presento un error al realizar el pago: ", error.response.data.errores);
              
              // registerResponsePayment({"parametros":parametrosPago.value.informacion, "respuesta": error.response.data.respuesta.mensaje, "emision_id": parametrosPago.value.informacion.emision_id}, true)
              Swal.fire({
                title: "Aviso",
                text: error.response.data.errores,
                icon: "error",
              });
              isLoading.value = false;
            }).finally(() => {
              isLoading.value = false;
              next();
            });
          }
        }
      } else {
        if(document.getElementById("selectMark").value == ''){
          errorMessageType.value = "Debe seleccionar una opción de tipo tarjeta(American Express, Visa o Mastercard)";
        }
        if(document.getElementById("selectBank").value == ''){
          errorMessageBank.value = "Debes seleccionar un banco para continuar";
        }
        if(document.getElementById("input_titularName").value == ''){
          errorMessageHolderName.value = "Falta agregar el nombre del titular para continuar, no puede estar vacío";
        }
        if(document.getElementById("input_titularName").value.length < 11 || document.getElementById("input_titularName").value.length > 40)
        {
          errorMessageHolderName.value = "El nombre del titular debe tener de 12 a 40 caracteres)";
        }

        if(document.getElementById("input_cardNumber").value == ''){
          errorMessageCard.value = "Falta agregar el número de la tarjeta para continuar, no puede estar vacía";
        }
        if(document.getElementById("selectMark").value != 'AmericanExpress' && document.getElementById("input_cardNumber").value.length != 19){
          errorMessageCard.value = "El número de caracteres numéricos deben de ser 16.";
        }
        
        if(document.getElementById("selectMark").value == 'AmericanExpress' && document.getElementById("input_cardNumber").value.length != 18){
          errorMessageCard.value = "El número de caracteres numéricos deben de ser 15.";
        }

        if(document.getElementById("input_monthExpirationDate").value == ''){
          errorMessageMonth.value = "Debes seleccionar un mes de vigencia de la tarjeta para continuar";
        }
        if(document.getElementById("input_yearExpirationDate").value == ''){
          errorMessageYear.value = "Debes seleccionar un año de vigencia de la tarjeta para continuar";
        }

        if(document.getElementById("input_cvv").value == ''){
          errorMessageCvv.value = "Falta agregar el código cvv de la tarjeta para continuar, no puede estar vacío";
        }
        if(document.getElementById("selectMark").value != 'AmericanExpress' && document.getElementById("input_cvv").value.length != 3){
          errorMessageCvv.value = "Es necesario completar el número de CVV de la tarjeta de 3 dígitos";
        }
        if(document.getElementById("selectMark").value == 'AmericanExpress' && document.getElementById("input_cvv").value.length != 4){
          errorMessageCvv.value = "Es necesario completar el número de CVV de la tarjeta de 4 dígitos";
        }
      }
    }

    // async function registerResponsePayment(json_response, error) {
    //   const json_payment = {
    //     "respuesta": json_response.respuesta,
    //     "parametros": json_response.parametros,
    //     "emision_id": json_response.emision_id,
    //     "error": error
    //   };
    //   await axios.post(multicrapi + '/pagos/registrar_solicitud', json_payment, config.value).then((response) => {
    //       console.log(response.data.respuesta);
    //     }).catch((error) => {
    //       console.log("Se presento un error al guardar el pago: ", error.response.data.errores);
    //       Swal.fire({
    //         title: "Aviso",
    //         text: "Se presentó un error al registrar el pago en Base de datos. Favor de comunicarse con un asesor. " + error.response.data.errores,
    //         icon: "error",
    //       });
    //       return [];
    //     }).finally(() => {
    //     });
    // }

    function validateMonths() {
      const date = new Date();
      const currrentYear = date.getFullYear();
      const currentMonth = date.getMonth() + 1;
      let month;
      if ("20" + yearExpirationDate.value == currrentYear) {
        filterMonths.value.months = months.value.months.filter(element => {
          month = element.value.charAt(0) == '0' ? element.value.replace('0', '') : element.value;
          return month >= currentMonth;
        })
      } else {
        filterMonths.value.months = months.value.months;
      }
    }

    function downloadPolicyPdf(){
      window.open(urlPolicyPdf.value, '_blank');
    }

    function next() {
      router.push({
        name: "PaymentCompleted"
      });
    }

    // async function sendEmail() {
    //   const sendEmail = new SendEmail();
    //   sendEmail.sendPolicymail(
    //     parametrosPago.value.informacion.cliente.email, 
    //     parametrosPago.value.informacion.emision_id
    //   ).then(() => {})
    //   .catch((error) => {
    //     console.log("Se presentó un error al enviar el correo: ", error);
    //   }).finally(() => {
    //   })
    // }

    return {
      getPaymentUrl,
      selectedProviderQuote,
      arrayMarkCards,
      arrayBanks,
      mark,
      bank,
      cardNumber,
      titularName,
      monthExpirationDate,
      yearExpirationDate,
      cvv,
      makePayment,
      step,
      months,
      years,
      next,
      issuanceResponseParams,
      markError,
      bankError,
      titularNameError,
      cardNumberError,
      cvvError,
      validateMonths,
      filterMonths,
      errorMessageType,
      errorMessageBank,
      errorMessageHolderName,
      errorMessageCard,
      errorMessageMonth,
      errorMessageYear,
      errorMessageCvv,
      sendPaymentLinkByMessage,
      paymentResponse,
      downloadPolicyPdf,
      urlPolicyPdf
    }
  }
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

p {
  @apply text-sm text-red-500 -mt-5;
}
</style>
